import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { Subscription, map, take } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import {
  checkIfCoachee,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import {
  getPreviousFormId,
  getSavedFormData,
  hasSavedFormData as hasStoredFormData,
} from 'src/app/private/shared/components/forms/view/view-field/ form-view.helpers';
import { ViewFormComponent } from 'src/app/private/shared/components/forms/view/view-form/view-form.component';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import {
  CompetencyFieldOutput,
  Form,
  FormLearnerBehaviorCreateDTO,
  FormStatus,
  FormSubmissionStatus,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from 'src/app/private/shared/dtos/forms.dto';
import { CoachingSessionUserType } from 'src/app/private/shared/enums/coaching-session-user-type.enum';
import { checkSessionDataType } from 'src/app/private/shared/helpers/coachee-log.utilities';
import { FormsSaveService } from 'src/app/private/shared/services/forms/forms-save.service';
import { UserPusherService } from 'src/app/private/shared/services/pusher/user.pusher.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { FormsService } from '../../../shared/services/forms/forms.service';

@Component({
  selector: 'app-observation-form',
  templateUrl: './observation-form-page.component.html',
  styleUrls: ['./observation-form-page.component.scss'],
})
export class ObservationFormPageComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  SESSION_STORAGE_KEY: string;

  LAST_FORM_KEY = 'last-form-id';

  lastFormId: number;

  @ViewChild('formView') formView: ViewFormComponent;

  @ViewChild('formSelect') formSelect: NgSelectComponent;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO;

  coachLogStatus$ = select(CoachingLogState.getCoachLogStatus);

  coachLogStatus: APILoadingStatus;

  disableLockFormButton = true;

  sessionLoaded = false;

  attendeeUsers: UserDTO[] = [];

  hasPresentAttendee = false;

  isCoach = false;

  isShadower = false;

  isCoachee = false;

  isAdmin = false;

  formDataLoading = true;

  loadStatus = {
    forms: false,
    sessionData: false,
    submissions: false,
  };

  user: User;

  isE2L = false;

  saveError = false;

  sessionId: number;

  formsList: Form[];

  selectedAttendee: CoachingSessionAttendeeDTO;

  selectedForm: Form | null = null;

  formSelectValue: Form;

  indicatorDeltas: CompetencyFieldOutput[] = [];

  @ViewChild('saveWarningModal') saveWarningModal: ModalComponent;

  @ViewChild('unloadedSavedDataModal') unloadedSavedDataModal: ModalComponent;

  formLocked = false;

  submissions: ObservationFormSubmission[] = [];

  SubmissionStatuses = FormSubmissionStatus;

  submissionStatus: FormSubmissionStatus = FormSubmissionStatus.NEW;

  createSubmissionData: ObservationFormSubmissionRawData;

  currentSubmission: ObservationFormSubmission;

  formSaving = false;

  notesActive = false;

  notesTabActive = true;

  resourcesActive = false;

  resourcesTabActive = true;

  apiUrl = EnvironmentService.apiUrl();

  subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private store: Store,
    private router: Router,
    private userPusherService: UserPusherService,
    protected formsSaveService: FormsSaveService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.unsetForm();
      this.formLocked = false;

      this.sessionId = parseInt(url['logId']);
      this.SESSION_STORAGE_KEY = `observation-form-${this.sessionId}`;
      this.store.dispatch(new FetchSessionData(this.sessionId));

      this.formsService.isLiveFormSubmission = true;
      this.formsService.setCoachlog(this.sessionId);
      this.formsService
        .getFormSubmissionsFromCoachingLog(this.sessionId)
        .subscribe((submissions) => {
          this.submissions = submissions;
          this.loadStatus.submissions = true;
          this.checkDataLoaded();
        });
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              checkSessionDataType(sessionData, 'observation', this.router);

              let observationFormDistrictId = this.sessionData.district.id;

              this.sessionData.attendees = this.sessionData.attendees.map(
                (attendee) => {
                  this.userPusherService.addChannel(
                    `growth-${attendee.userId}`
                  );
                  if (attendee.user.district_id) {
                    observationFormDistrictId = attendee.user.district_id;
                  }
                  return {
                    ...attendee,
                    fullName: `${attendee.user.profile.first_name} ${attendee.user.profile.last_name}`,
                  };
                }
              );
              this.formsService
                .loadForms(
                  observationFormDistrictId,
                  1000,
                  1,
                  undefined,
                  FormStatus.PUBLISHED
                )
                .subscribe(([forms]) => {
                  this.formsList = forms;
                  this.loadStatus.forms = true;
                  this.checkDataLoaded();
                });
              this.loadStatus.sessionData = true;
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
              this.isCoach =
                this.sessionData.currentUsersRole ===
                CoachingSessionUserType.COACH;
              this.isShadower = checkIfShadower(this.sessionData);
              this.isAdmin =
                this.user.roles.includes(UserRole.E2L_EMPLOYEE) ||
                this.user.roles.includes(UserRole.DISTRICT_ADMIN);
              this.isCoachee = checkIfCoachee(this.sessionData);
              this.checkDataLoaded();
            }
          })
        )
        .subscribe();
      this.coachLogStatus$.subscribe((status) => {
        if (status.loaded) {
          this.coachLogStatus = status;
          this.sessionLoaded = true;
        }
      });
      this.subs.push(
        this.formsService.promptSave.subscribe((save) => {
          if (save) {
            this.submitForm();
          }
        })
      );
    });
  }

  ngAfterViewInit(): void {
    this.subs.push(
      this.formsSaveService.promptUnsavedModal.subscribe((sessionId) => {
        if (sessionId === this.sessionId) {
          this.saveWarningModal.open();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.formsSaveService.clearNavigationQueue(this.sessionId);
    this.formsService.isLiveFormSubmission = false;
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  checkDataLoaded() {
    if (
      this.loadStatus.forms &&
      this.loadStatus.sessionData &&
      this.loadStatus.submissions
    ) {
      if (this.isCoach || this.isShadower || this.isE2L || this.isAdmin) {
        this.attendeeSelected(this.sessionData.attendees[0]);
      } else {
        const attendeeIdx = this.sessionData.attendees.findIndex(
          (attendee) => attendee.userId === this.user.id
        );
        if (attendeeIdx > -1) {
          this.attendeeSelected(this.sessionData.attendees[attendeeIdx]);
        }
      }
      if (hasStoredFormData(this.SESSION_STORAGE_KEY)) {
        if (!this.unloadedSavedDataModal.isOpen) {
          this.unloadedSavedDataModal.open();
        }
      }
      this.formDataLoading = false;

      if (this.formsList) {
        this.restoreLastForm();
      }
    }
  }

  lockForm() {
    this.formLocked = true;
    if (this.selectedForm) {
      StorageService.storeItem(this.LAST_FORM_KEY, {
        formId: this.selectedForm.id,
      });
    }
    this.submitForm();
  }

  unsetForm() {
    if (this.selectedForm) {
      this.selectedForm = null;
      if (this.formSelect) {
        this.formSelect.handleClearClick();
      }
    }
  }

  restoreLastForm() {
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      this.lastFormId = getPreviousFormId(this.LAST_FORM_KEY);
      if (this.lastFormId) {
        const formIndex = this.formsList.findIndex(
          (form) => form.id === this.lastFormId
        );
        if (formIndex) {
          this.formSelectValue = this.formsList[formIndex];
          this.updateFormSelect(this.formsList[formIndex]);
        }
      }
    }
  }

  attendeeSelected(user: CoachingSessionAttendeeDTO) {
    this.selectedAttendee = user;
    this.formsService.assessedUser = user.user;
    if (this.submissions && this.submissions.length > 0) {
      const submissionIdx = this.submissions.findIndex(
        (submission) =>
          submission.observedUserId === this.selectedAttendee.userId
      );
      if (submissionIdx > -1) {
        this.currentSubmission = this.submissions[submissionIdx];
        this.selectedForm = this.formsList.find(
          (form) => form.id === this.currentSubmission.formId
        ) as Form;
        this.formLocked = true;
        this.submissionStatus = FormSubmissionStatus.SUBMITTED;
        if (this.formView) {
          this.formView.submissionStatus = this.submissionStatus;
          this.formView.submissionData = this.currentSubmission;
        }
        return;
      }
    }
    this.submissionStatus = FormSubmissionStatus.NEW;
    this.createNewSubmission();
    if (this.formView) {
      this.formView.submissionStatus = this.submissionStatus;
      delete this.formView.submissionData;
    }
  }

  createNewSubmission() {
    this.createSubmissionData = {
      formId: this.selectedForm?.id || 0,
      submitterUser: this.user,
      submissionData: [],
      coachingLogId: this.sessionId,
      observedUserId: this.selectedAttendee.userId,
    };
  }

  commitIndicatorDeltas(coachlogId?: number) {
    const logId = coachlogId || this.sessionId;
    if (this.indicatorDeltas.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of this.indicatorDeltas) {
        if (field.assessmentsToRemove.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const assessment of field.assessmentsToRemove) {
            this.formsService.removeAssessment(assessment);
          }
        }
        if (field.assessmentsToAdd.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const assessment of field.assessmentsToAdd) {
            if (!assessment.committedToDb) {
              this.formsService.addAssessment({
                ...(assessment.assesmentData as FormLearnerBehaviorCreateDTO),
                coachlogId: logId,
              });
            }
          }
        }
      }
      this.indicatorDeltas = [];
    }
  }

  submitForm(navigateAfterSubmit = false) {
    this.formLocked = true;
    this.formSaving = true;
    this.loadDataIntoCurrentSubmission();
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      this.formsService
        .createFormSubmission(this.createSubmissionData)
        .subscribe((submission) => {
          take(1);
          this.commitIndicatorDeltas(submission.coachingLogId);
          this.submissions.push(submission);
          this.attendeeSelected(this.selectedAttendee);
          this.formsService.repositionFormOnSave = true;
          this.deleteSavedFormData(navigateAfterSubmit);
          this.formSaving = false;
          this.saveError = false;
        });
    } else {
      this.formsService
        .updateFormSubmission(this.currentSubmission)
        .subscribe((submission) => {
          take(1);
          this.commitIndicatorDeltas();
          const idx = this.submissions.findIndex((s) => s.id === submission.id);
          if (idx > -1) {
            this.submissions[idx] = submission;
          }
          this.formSaving = false;
          this.deleteSavedFormData(navigateAfterSubmit);
        });
    }
  }

  loadDataIntoCurrentSubmission() {
    if (hasStoredFormData(this.SESSION_STORAGE_KEY)) {
      const latestData = getSavedFormData(
        this.SESSION_STORAGE_KEY
      ) as ObservationFormSubmissionRawData;
      this.currentSubmission = {
        ...this.currentSubmission,
        ...latestData,
        saveTimestampMS: Date.now(),
      };
      this.updateSubmission(latestData);
    }
  }

  navigatePreviousScreen() {
    this.router.navigate(['../info'], { relativeTo: this.route });
  }

  navigateNextScreen() {
    this.formsSaveService.addToNavigationQueue(this.sessionId, [
      ['../summary'],
      { relativeTo: this.route },
    ]);
    this.submitForm(true);
  }

  updateFormSelect(form: Form) {
    this.disableLockFormButton = false;
    this.selectedForm = form;
    if (this.submissionStatus === FormSubmissionStatus.NEW) {
      this.createSubmissionData.formId = form?.id;
    } else {
      this.currentSubmission.formId = form?.id;
    }
  }

  deleteSavedFormData(navigateWithQueue = false): void {
    StorageService.removeItem(this.SESSION_STORAGE_KEY);
    this.formsSaveService.clearNavigationHold(this.sessionId);
    this.unloadedSavedDataModal.close();
    if (navigateWithQueue) {
      this.formsSaveService.navigateFromQueue(this.sessionId);
    }
  }

  loadFormDataForUse(closeModal = false): void {
    if (hasStoredFormData(this.SESSION_STORAGE_KEY)) {
      const latestData = getSavedFormData(
        this.SESSION_STORAGE_KEY
      ) as ObservationFormSubmissionRawData;
      if (!this.selectedForm) {
        const foundForm = this.formsList.find(
          (form) => form.id === latestData.formId
        );
        if (foundForm) {
          this.formSelectValue = foundForm;
          this.updateFormSelect(foundForm);
          this.createSubmissionData.submissionData = (
            latestData as ObservationFormSubmissionRawData
          ).submissionData;
          this.indicatorDeltas = latestData.indicatorDeltas
            ? latestData.indicatorDeltas
            : [];
          this.submitForm();
        } else {
          this.deleteSavedFormData();
        }
      } else {
        this.loadDataIntoCurrentSubmission();
      }
    }
    if (closeModal) {
      this.unloadedSavedDataModal.close();
    }
  }

  updateIndicatorDeltas(indicatorDeltas: CompetencyFieldOutput[]) {
    this.indicatorDeltas = indicatorDeltas;
    const existingData = getSavedFormData(this.SESSION_STORAGE_KEY);
    StorageService.storeItem(this.SESSION_STORAGE_KEY, {
      ...existingData,
      indicatorDeltas,
    });
  }

  updateSubmission(formData: ObservationFormSubmissionRawData) {
    const baseData = this.currentSubmission
      ? this.currentSubmission
      : this.createSubmissionData;
    StorageService.storeItem(this.SESSION_STORAGE_KEY, {
      ...baseData,
      submissionData: {
        ...formData,
        saveTimestampMS: Date.now(),
      },
    });
    this.formsSaveService.addNavigationHold(this.sessionId);
  }

  toggleNotes(): void {
    this.notesActive = !this.notesActive;
    this.notesTabActive = !this.notesTabActive;
  }

  toggleResources(): void {
    this.resourcesActive = !this.resourcesActive;
    this.resourcesTabActive = !this.resourcesTabActive;
  }
}
