import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { CreateEditCompetencyModalComponent } from 'src/app/private/shared/components/competency/create-edit-competency-modal/create-edit-competency-modal.component';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { TopicBuilderService } from 'src/app/private/shared/services/topic-builder/topic-builder.service';
import { CompetencyBuilderStandardAPIResponse } from 'src/app/private/shared/types/responses/competency-builder.responses';
import {
  CompetencyStandardAPIResponse,
  CompetencyStandardGroupAPIResponse,
} from '../../../shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-topics-competencies-page',
  templateUrl: './topics-competencies-page.component.html',
  styleUrl: './topics-competencies-page.component.scss',
})
export class TopicsCompetenciesPageComponent {
  subs: Subscription[] = [];

  user: User;

  districtCode: string;

  topicId: number;

  topic: CompetencyBuilderStandardAPIResponse;

  district: DistrictDTO;

  isLoading = true;

  tableData: CompetencyStandardAPIResponse[];

  @ViewChild('createEditCompetencyModal')
  createEditCompetencyModal: CreateEditCompetencyModalComponent;

  createCompetencyLoading = false;

  constructor(
    private route: ActivatedRoute,
    private districtListService: DistrictListService,
    private topicBuilderService: TopicBuilderService
  ) {
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtListService
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
          }
          this.getTopic();
        }
      });
  }

  getTopic() {
    this.topicId = parseInt(
      this.route.snapshot.paramMap.get('topicId') as string
    );

    this.topicBuilderService.getTopic(this.topicId).subscribe((res) => {
      this.topic = res.item;
      this.tableData = res.item.standardSet.standardGroups.flatMap(
        (group: CompetencyStandardGroupAPIResponse) => group.standards
      );
      if (this.createEditCompetencyModal) {
        this.topicBuilderService.createCompetencyCompleted.subscribe(() => {
          this.createCompetencyLoading = false;
        });
      }
    });
  }

  dropCompetencyItem(
    competency: CompetencyStandardAPIResponse[],
    event: CdkDragDrop<CompetencyStandardAPIResponse[]>
  ) {
    const sortData = this.topicBuilderService.calcSortChange(
      competency,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(competency, event.previousIndex, event.currentIndex);
    if (sortData) {
      this.topicBuilderService.resortCompetency(sortData).subscribe();
    }
  }
}
