<footer
  id="main-footer"
  role="contentinfo"
  class="d-flex flex-wrap py-2 border-top justify-content-center justify-content-md-between">
  <div class="p-1">
    <a href="https://engage2learn.org/" aria-label="Link to engage2Learn.org">
      <img
        src="../../../assets/powered_by_e2l.png"
        alt="powered by engage2learn logo"
        style="width: 200px" />
    </a>
  </div>
  <div class="p-1" style="font-size: x-small">
    <div>
      &copy; {{ year }} engage2learn. All rights reserved.
      <strong>
        <a
          href="legal/privacy-policy"
          aria-label="link to engage2learn privacy policy">
          Privacy
        </a>
        |
        <a href="legal/terms" aria-label="link to engage2learn terms of use">
          Terms
        </a>
      </strong>
    </div>
  </div>
</footer>
