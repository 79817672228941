<app-modal
  #modal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="todo">
    <ng-container *ngIf="!isPlanTodo; else planItemView">
      <label class="label-text fw-bold mt-0">Title</label>
      <p>{{ todo.content }}</p>
      <label class="label-text fw-bold mt-0">Due Date</label>
      <p>{{ todo.dueDate | formatDate }}</p>
    </ng-container>
    <ng-template #planItemView>
      <ng-container *ngIf="todo.planTodoMeta">
        <label class="label-text fw-bold mt-0">Title</label>
        <p>{{ todo.content }}</p>
        <label class="label-text fw-bold mt-0">Description</label>
        <p>{{ todo.description }}</p>
        <label class="label-text fw-bold mt-0">Implementation Plan Title</label>
        <p>{{ todo.planTodoMeta.planTitle }}</p>
        <label class="label-text fw-bold mt-0">Item Type</label>
        <p>
          {{
            todo.planTodoMeta.deliverableDisplayName ||
              todo.planTodoMeta.actionItemDisplayName
          }}
        </p>
        <label class="label-text fw-bold mt-0">Due Date</label>
        <p>{{ todo.dueDate | formatDate : "MMM d, yyyy" : timezone }}</p>
      </ng-container>
    </ng-template>
    <div
      footerContent
      *ngIf="isPlanTodo && todo.planTodoMeta"
      [style.text-align]="'right'">
      <a
        *ngIf="getPlanLink(todo) as link"
        [routerLink]="link.path"
        [queryParams]="link.queryParams"
        type="button"
        class="btn btn-md btn-primary"
        href="javascript:void(0);"
        target="_blank">
        Go to
        {{
          todo.planTodoMeta.deliverableDisplayName ||
            todo.planTodoMeta.actionItemDisplayName
        }}
      </a>
    </div>
  </ng-container>
</app-modal>
