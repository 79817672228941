import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { TopicSearchParams } from 'src/app/private/pages/topics-page/topics-page-helpers';

export interface CreateUpdateTopicPayload {
  title: string;
  is_smart: number;
  types: number[];
  districts?: number[];
}

export interface UpdateCompetencyPayload {
  title?: string;
  content?: string;
  rubric_id: number;
}

export interface UpdateIndicatorGroupPayload {
  title: string;
}

export interface CompetencyFilterParams {
  sort_order: string;
  sort_dir: string;
}

export interface SortData {
  id: number;
  direction: string;
  refId: number;
}
@Injectable({
  providedIn: 'root',
})
export class TopicBuilderService {
  createCompetencySource = new BehaviorSubject(false);

  createCompetencyCompleted = this.createCompetencySource.asObservable();

  deleteStrandSource = new BehaviorSubject(false);

  deleteStrandCompleted = this.deleteStrandSource.asObservable();

  topicBuilderAPILoadingSource = new BehaviorSubject(false);

  topicBuilderAPILoading = this.topicBuilderAPILoadingSource.asObservable();

  constructor(private apiService: APICoreService) {}

  getTopicBuilderTopics(payload: TopicSearchParams) {
    return this.apiService.getRequest('egrowe/rubric-builder', payload);
  }

  getTopic(topicId: number) {
    return this.apiService.getRequest(`egrowe/rubric-builder/${topicId}`);
  }

  createTopic(payload: CreateUpdateTopicPayload) {
    return this.apiService.postRequest('egrowe/rubric-builder', payload);
  }

  updateTopic(rubricId: number, payload: CreateUpdateTopicPayload) {
    return this.apiService.putRequest(
      `egrowe/rubric-builder/${rubricId}`,
      payload
    );
  }

  deleteTopic(topicId: number) {
    return this.apiService.deleteRequest(`egrowe/rubric-builder/${topicId}`);
  }

  getDistrictCompetencies(
    districtId: number,
    payload: CompetencyFilterParams,
    topicIds: number[]
  ) {
    let rubricString = '';
    topicIds.forEach((id) => {
      rubricString += `?topics[]=${id}`;
    });
    return this.apiService.getRequest(
      `growelab/district/${districtId}/competencies${rubricString}`,
      payload
    );
  }

  turnCompetencyOn(
    district_id: number,
    egrowe_standard_id: number,
    egrowe_rubric_id: number
  ) {
    return this.apiService.postRequest('growelab/competencies-for-coaching', {
      district_id,
      egrowe_standard_id,
      egrowe_rubric_id,
    });
  }

  turnCompetencyOff(district_id: number, egrowe_standard_id: number) {
    return this.apiService.deleteRequest('growelab/competencies-for-coaching', {
      district_id,
      egrowe_standard_id,
    });
  }

  getDistrictRubrics(district_id: number) {
    return this.apiService.getRequest(
      `egrowe/district-rubrics?per_page=1000&district_id=${district_id}`
    );
  }

  deleteRubric(rubricId: number) {
    return this.apiService.deleteRequest(`egrowe/rubric-builder/${rubricId}`);
  }

  createCompetency(rubricId: number, standardGroupId: number) {
    return this.apiService.postRequest(
      'egrowe-rubric-builder/create-standard',
      { rubric_id: rubricId, standard_group_id: standardGroupId }
    );
  }

  deleteCompetency(competencyId: number) {
    return this.apiService.deleteRequest(
      `egrowe-rubric-builder/delete-standard/${competencyId}`
    );
  }

  updateCompetency(competencyId: number, payload: UpdateCompetencyPayload) {
    return this.apiService.putRequest(
      `egrowe-rubric-builder/update-standard/${competencyId}`,
      payload
    );
  }

  getIndicatorSet(rubricId: number, standardId: number) {
    return this.apiService.getRequest(
      `egrowe-rubric-builder/${rubricId}/standard/${standardId}/indicator-set`
    );
  }

  createIndicatorGroup(indicatorSetId: number) {
    return this.apiService.postRequest(
      `egrowe-rubric-builder/indicator-set/${indicatorSetId}/indicator-group`
    );
  }

  deleteIndicatorGroup(indicatorGroupId: number) {
    return this.apiService.deleteRequest(
      `egrowe-rubric-builder/indicator-group/${indicatorGroupId}`
    );
  }

  updateIndicatorGroup(
    indicatorGroupId: number,
    payload: UpdateIndicatorGroupPayload
  ) {
    return this.apiService.putRequest(
      `egrowe-rubric-builder/indicator-group/${indicatorGroupId}`,
      payload
    );
  }

  updateIndicatorContent(indicatorId: number, content: string) {
    return this.apiService.putRequest(
      `egrowe-rubric-builder/indicator/${indicatorId}`,
      {
        content,
      }
    );
  }

  resortCompetency(sortData: SortData) {
    return this.apiService.putRequest(
      `egrowe-rubric-builder/standard/${sortData.id}/sort/${sortData.direction}/${sortData.refId}`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  calcSortChange(sortItems: any[], oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex) {
      return null;
    }

    let direction = 'after';

    // get the ID of the item at the oldIndex (this is what we are moving)
    const itemId = sortItems[oldIndex].id;
    // get the ID of the item at the newIndex (this is where we are moving it to)
    const refId = sortItems[newIndex].id;

    if (oldIndex > newIndex) {
      direction = 'before';
    }

    return { id: itemId, direction, refId };
  }
}
