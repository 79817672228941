import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, map, take } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { ResourceListItemDTO } from '../../dtos/resources.dto';
import { CoachingLogPusherService } from '../../services/coaching-log/coaching-log-pusher.service';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { PusherResourceAPIResponseItem } from '../../types/responses/coaching-log.responses';
import { SelectResourceComponent } from '../resources/select-resource/select-resource.component';

@Component({
  selector: 'app-coaching-log-resources',
  templateUrl: './coaching-log-resources.component.html',
  styleUrl: './coaching-log-resources.component.scss',
})
export class CoachingLogResourcesComponent implements OnInit, OnDestroy {
  @Input() user: User | null;

  @Input() resourcesActive: boolean;

  @Input() sessionId: number;

  @Output() readonly hideResourcesEvent = new EventEmitter<boolean>();

  public selectResourceRef: SelectResourceComponent;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  subs: Subscription[] = [];

  resources: PusherResourceAPIResponseItem[];

  userDistrictId: number | null;

  loading: boolean;

  constructor(
    public coachingService: CoachingLogService,
    private coachingLogPusherService: CoachingLogPusherService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) => filterFn(this.sessionId)),
          take(1)
        )
        .subscribe((sessionData) => {
          this.sessionData = sessionData;
          this.getResources();
        })
    );

    if (this.user) {
      this.userDistrictId = this.user?.district ? this.user.district.id : null;
    }

    this.coachingLogPusherService.resourcesUpdatedNotifier.subscribe(() => {
      this.getResources();
    });
  }

  toggleResources(): void {
    this.hideResourcesEvent.emit(true);
  }

  getResources() {
    this.coachingService
      .getResourcesForSession(this.sessionId)
      .subscribe((resources) => {
        if (resources.items) {
          this.resources = resources.items;
          this.loading = false;
        }
      });
  }

  addResource(resource: ResourceListItemDTO) {
    this.loading = true;
    this.coachingService
      .addResourceToSession(resource.id, this.sessionId)
      .subscribe(() => {});
  }

  removeResource(event: number) {
    this.loading = true;
    this.resources = this.resources.filter((resource) => resource.id !== event);
    this.coachingService
      .deleteResourceFromSession(event, this.sessionId)
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
