import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  ResourceFiltersDTO,
  ResourceListItemDTO,
} from '../../dtos/resources.dto';
import { defaultSearchFilterFreeze } from './resource.helpers';

@Injectable({
  providedIn: 'root',
})
export class ResourcesSearchService {
  // search filters
  public searchFilters = new BehaviorSubject(defaultSearchFilterFreeze());

  public editedSearchFilters = new BehaviorSubject(defaultSearchFilterFreeze());

  public searchTrigger = new Subject<boolean>();

  // search results
  private searchResultsData: ResourceListItemDTO[] | null = null;

  private searchResultsSource = new BehaviorSubject(this.searchResultsData);

  public searchResultsObs = this.searchResultsSource.asObservable();

  // search results meta
  private searchResultsMetaData: PaginationDTO | null = null;

  private searchResultsMetaSource = new BehaviorSubject(
    this.searchResultsMetaData
  );

  public searchResultsMetaObs = this.searchResultsMetaSource.asObservable();

  private restricToShelfData = false;

  private restricToShelfSource = new BehaviorSubject(this.restricToShelfData);

  public restricToShelf = this.restricToShelfSource.asObservable();

  constructor(public apicoreService: APICoreService) {}

  performSearch() {
    const filterFreeze: ResourceFiltersDTO = {
      ...defaultSearchFilterFreeze(),
      ...this.searchFilters.value,
      limit_to_shelf: this.restricToShelfSource.value ? 1 : 0,
    };

    this.searchFilters.next(filterFreeze);

    return this.apicoreService.getRequest('elearn/search', filterFreeze).pipe(
      map((results) => {
        let resourceList: ResourceListItemDTO[] = [];
        if (results.items) {
          resourceList = results.items;
        } else {
          resourceList = [];
        }
        // we populate observables, as well as return the resourcelist.
        this.searchResultsSource.next(resourceList);
        this.searchResultsMetaSource.next(results._meta);
        return resourceList;
      })
    );
  }

  setFilters(filtersDelta: ResourceFiltersDTO) {
    this.searchFilters.next({
      ...this.searchFilters.value,
      ...filtersDelta,
    });
    this.searchTrigger.next(true);
  }

  setEditedFilters(filtersDelta: ResourceFiltersDTO) {
    this.editedSearchFilters.next({
      ...this.editedSearchFilters.value,
      ...filtersDelta,
    });
  }

  resetFilters() {
    this.searchFilters.next(defaultSearchFilterFreeze());
  }

  resetEditedFilters() {
    this.editedSearchFilters.next(this.searchFilters.value);
  }

  applyEditedFilters() {
    this.setFilters(this.editedSearchFilters.value);
  }

  updateRestriction(bool: boolean) {
    this.restricToShelfSource.next(bool);
  }

  getTotalResources() {
    return this.apicoreService.getRequest('public-stats/eplc-resources');
  }
}
