<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Create Status Report',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    showFooter: false,
    closeOnBgClick: false,
    options: {
      size: 'lg',
    },
  }"
  (isDismissing)="resetForm()"
  (isClosing)="resetForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="activeSection === 'form'">
    <form #previewForm="ngForm" class="mb-5">
      <div class="form-row">
        <label for="recipients" class="form-label w-100">Recipients</label>
        <textarea
          aria-label="Recipients"
          placeholder="Enter email addresses separated by commas (,)"
          #recipients="ngModel"
          name="recipients"
          class="form-control"
          rows="2"
          [(ngModel)]="emailRecipients"
          [ngClass]="{
            'is-invalid': recipients.invalid && recipients.touched
          }"
          required></textarea>
      </div>

      <div class="form-row">
        <label for="email_subject" class="form-label w-100"
          >Email Subject</label
        >
        <textarea
          aria-label="Email Subject"
          placeholder="Subject of Email"
          #email_subject="ngModel"
          name="email_subject"
          class="form-control"
          rows="1"
          [(ngModel)]="emailSubject"
          [ngClass]="{
            'is-invalid': email_subject.invalid
          }"
          required></textarea>
      </div>

      <div class="form-row">
        <label for="highlightsEditor" class="form-label">Highlights</label>
        <div
          name="highlightsEditor"
          [froalaEditor]="froalaOptions"
          (froalaInit)="initializeLink($event)"
          [(froalaModel)]="highlights"></div>
      </div>
    </form>
    <div class="custom-footer">
      <div class="d-flex justify-content-between">
        <button type="button" class="btn" (click)="closeModal()">Cancel</button>

        <button
          [disabled]="
            !highlights || !emailRecipients || !emailSubject || isLoading
          "
          type="button"
          class="btn btn-md btn-primary"
          (click)="getEmailPreview()">
          <ng-container *ngIf="isLoading">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ng-container>
          Preview
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeSection === 'preview'">
    <div
      class="d-flex justify-content-start"
      style="border-bottom: solid 1px #dee2e6; padding-bottom: 15px">
      <button
        type="button"
        class="btn me-2"
        style="min-width: 160px"
        (click)="changeSection('form')">
        < &nbsp;Back to Editing
      </button>
      <div class="ps-4" style="border-left: solid 1px #ccc">
        <div class="info d-flex mb-2">
          <div class="start fw-800">Subject:</div>
          <div>
            {{ emailSubject }}
          </div>
        </div>
        <div class="info d-flex">
          <div class="start fw-800">Recipients:</div>
          <div class="recipients">{{ emailRecipients }}</div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <app-safehtml [html]="emailBody"></app-safehtml>
    </div>

    <ng-container *ngIf="errorMessage">
      <div class="alert alert-danger text-center mt-0 mb-3" role="alert">
        {{ errorMessage }}
      </div>
    </ng-container>

    <div class="custom-footer">
      <div class="d-flex justify-content-end">
        <button
          [disabled]="errorMessage || isLoading"
          type="button"
          class="btn btn-md btn-primary"
          (click)="saveReportData()">
          <ng-container *ngIf="isLoading">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ng-container>
          Send
        </button>
      </div>
    </div>
  </ng-container>
</app-modal>
