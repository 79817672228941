<div class="container topics-page">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3 roster-container">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="district && topic; else loadingTemplate">
      <a
        routerLink="/settings/districts/{{ districtCode }}/topics"
        class="text-decoration-none text-body-secondary"
        title="Back to all Topics">
        < Back to all Topics
      </a>
      <div class="row d-flex justify-content-between my-3">
        <div class="col-12 col-md-6 order-2 order-md-1">
          <h2 class="fw-600 mb-0 ms-1">{{ topic.title }}</h2>
        </div>
        <div
          class="d-flex col-12 col-md-6 order-1 order-md-2 justify-content-center justify-content-md-end my-3 my-md-0">
          <button
            class="btn btn-primary btn-create-competency"
            style="height: min-content"
            [disabled]="createCompetencyLoading"
            (click)="
              createEditCompetencyModal.createCompetency();
              createCompetencyLoading = true
            ">
            <ng-container
              *ngIf="createCompetencyLoading; else createCompetency">
              <div class="spinner-border spinner-border-sm" role="status"></div>
              Creating Competency
            </ng-container>
            <ng-template #createCompetency>
              <i class="bi bi-plus"></i>
              Create a Competency
            </ng-template>
          </button>
        </div>
      </div>
      <ng-container *ngIf="tableData">
        <ng-container *ngIf="tableData.length > 0; else noCompetencies">
          <table id="comptable" class="table">
            <thead>
              <tr>
                <th>Competency</th>
                <th class="icon-col"></th>
                <th class="icon-col"></th>
              </tr>
            </thead>
            <tbody
              cdkDropList
              [cdkDropListData]="tableData"
              class="drop-competencies"
              (cdkDropListDropped)="dropCompetencyItem(tableData, $event)">
              <tr
                *ngFor="let competency of tableData"
                cdkDragBoundary=".drop-competencies"
                cdkDragLockAxis="y"
                cdkDrag>
                <td style="vertical-align: middle">
                  {{ competency.title }}
                </td>
                <td class="text-end" style="vertical-align: middle">
                  <i
                    (click)="createEditCompetencyModal.openModal(competency)"
                    ngbTooltip="Edit Competency"
                    class="bi bi-pencil cursor-pointer color-tertiary"></i>
                </td>
                <td>
                  <div
                    *ngIf="tableData.length > 1; else noDrag"
                    class="drag-handle"
                    cdkDragHandle>
                    <i class="bi bi-grip-vertical"></i>
                  </div>
                  <ng-template #noDrag>
                    <div class="no-drag-handle"></div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
      <ng-template #noCompetencies>
        <tr>
          <td colspan="5">
            <div
              class="d-flex flex-column align-items-center justify-content-center w-100"
              style="min-height: 400px">
              <h2>No Competencies Found</h2>
              <p>Please create a competency</p>
            </div>
          </td>
        </tr>
      </ng-template>
    </ng-container>
  </div>
</div>
<app-create-edit-competency-modal
  #createEditCompetencyModal
  [topic]="topic"
  (refreshCompetencies)="getTopic()" />
<ng-template #loadingTemplate>
  <div class="w-100" style="background-color: #fff; height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>
