<div class="container">
  <app-general-header title="Coaching Session"></app-general-header>
  <div class="card border-0 p-3 p-lg-5 min-vh-75">
    <ng-container *ngIf="sessionLoaded; else loadingTemplate">
      <ng-container *ngIf="sessionData; else sessionErrorMessage">
        <h2 class="fs-1 fw-bold text-center my-3">
          {{ sessionData.title }}
        </h2>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <ng-template
            [ngIf]="
              coachingService.logPusherService.attendeeRosters[sessionId]
                .length > 0
            ">
            <div class="d-flex flex-row align-items-center">
              <div class="me-1 text-body-secondary">Current Viewers</div>
              <app-avatar
                style="margin-left: 5px"
                avatarSize="small"
                *ngFor="let attendee of pusherAttendees"
                [userId]="attendee.id"
                [avatarData]="attendee.avatar"
                [grayScale]="!attendee.samePage"
                data-testid="attendee-avatar"></app-avatar>
            </div>
          </ng-template>
          <div class="col text-end">
            <button
              routerLink="/general/log/{{ sessionId }}/notes"
              class="btn btn-primary btn-next"
              [disabled]="!isTimeValid"
              style="pointer-events: auto">
              Let's Begin <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="container mt-3">
          <form>
            <div class="row">
              <div class="col-lg-4 p-3">
                <h3>Session Owner</h3>
                <div class="d-flex flex-nowrap align-items-center my-3">
                  <app-avatar
                    [profile]="sessionData.user.profile"
                    [avatarSize]="'medium'">
                  </app-avatar>
                  <div class="username ms-2">
                    {{ sessionData.user.profile.first_name }}
                    {{ sessionData.user.profile.last_name }}
                  </div>
                </div>
                <p
                  *ngIf="
                    sessionCreator &&
                    sessionCreator.profile &&
                    sessionCreator.id !== sessionData.user.id
                  "
                  class="text-muted fs-13">
                  Session created by {{ sessionCreator.profile.first_name }}
                  {{ sessionCreator.profile.last_name }}
                </p>
                <h3 class="mt-4">Participant(s)</h3>
                <ng-container
                  *ngIf="sessionData.attendees.length > 0; else noParticipants">
                  <app-coaching-attendee
                    *ngFor="let attendee of sessionData.attendees"
                    [attendee]="attendee"
                    [isCoach]="isCoach || isCreator"
                    [absentReasonsList]="absentReasonsList"
                    (removedAttendee)="removeCoachee($event)" />
                </ng-container>
                <ng-template #noParticipants>
                  <p class="text-body-secondary fw-light">
                    No particpants have been added to this session.
                  </p>
                </ng-template>
                <div *ngIf="isCoach || isCreator" class="mt-4">
                  <label class="form-label">Add Participant</label>
                  <ng-container
                    *ngIf="
                      sessionData.attendees.length +
                        sessionData.shadowers.length <=
                        8;
                      else maxAttendees
                    ">
                    <app-user-select
                      [districtId]="districtId"
                      [includeAllB2bDistricts]="includeAllB2bDistricts"
                      [clearAfterSelection]="true"
                      (selectedUser)="addCoachee($event)" />
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4 p-3">
                <h3>Session Info</h3>
                <ng-container *ngIf="isCoach || isCreator">
                  <label class="w-100 form-label" for="title">Title</label>
                  <input
                    class="form-control"
                    type="text"
                    id="title"
                    aria-label="Title"
                    appAutosave
                    [(ngModel)]="sessionData.title"
                    [ngModelOptions]="{ standalone: true }"
                    (autosave)="saveTitle()"
                    class="form-control" />
                </ng-container>
                <label class="form-label">Type</label>
                <div class="mb-3 d-flex align-items-center">
                  <div style="flex-grow: 1">{{ sessionData.type.title }}</div>
                  <div class="d-flex">
                    <button
                      *ngIf="isCoach || isCreator"
                      class="bg-transparent fs-12 border-0 p-0 ms-2"
                      title="Edit sesson type"
                      aria-label="Edit session type"
                      (click)="openSessionTypeModal()">
                      <i class="bi bi-pencil-fill"></i>
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="isCoach || isCreator; else nonCoachView">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      (change)="onCheckboxChange($event)"
                      [(ngModel)]="sessionData.isPrivate"
                      [ngModelOptions]="{ standalone: true }"
                      type="checkbox"
                      id="is-private" />
                    <label class="form-check-label" for="is-private">
                      Private Session
                      <div class="form-text">
                        Private sessions can only be viewed by the owner,
                        participants(s), and shadower of the session.
                      </div></label
                    >
                  </div>
                </ng-container>
                <ng-template #nonCoachView>
                  <ng-container
                    *ngIf="sessionData.currentUsersRole === 'coachee'">
                    <div class="form-check">
                      <input
                        disabled
                        class="form-check-input"
                        (change)="onCheckboxChange($event)"
                        [(ngModel)]="sessionData.isPrivate"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                        id="is-private" />
                      <label class="form-check-label" for="is-private">
                        Private Session
                        <div class="form-text">
                          Private sessions can only be viewed by the owner,
                          participants(s), and shadower of the session.
                        </div></label
                      >
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      sessionData.currentUsersRole === 'shadower' &&
                      sessionData.isPrivate
                    ">
                    <div class="mt-1 mb-3">
                      <label class="w-100 form-label">Private Session</label>

                      <div class="form-text">
                        Private sessions can only be viewed by the owner,
                        participants(s), and shadower of the session.
                      </div>
                    </div>
                  </ng-container>
                </ng-template>

                <ng-container
                  *ngIf="isCoach || isCreator; else coacheeDatetimeView">
                  <label class="w-100 form-label">Date & Time</label>
                  <app-datepicker
                    #datePicker
                    (validTime)="isTimeValid = $event"
                    [initialDateUnixTimestamp]="sessionData.startDatetime"
                    (dateSelectedUnixTimestamp)="updateDateTime($event)"
                    [defaultStartTime]="logStart"
                    [defaultEndTime]="logEnd"
                    [timezone]="sessionData.timezone"
                    [timepicker]="true"></app-datepicker>
                </ng-container>
                <ng-template #coacheeDatetimeView>
                  <div class="form-label">Date & Time</div>
                  {{
                    sessionData.startDatetime
                      | formatDate : "MMM. d, yyyy" : sessionData.timezone
                  }},
                  {{
                    sessionData.startDatetime
                      | formatDate : "h:mm a" : sessionData.timezone
                  }}<br />
                  {{ sessionData.timezone }}
                </ng-template>
              </div>
              <div class="col-lg-4 p-3">
                <div class="d-flex flex-column h-100">
                  <div style="flex-grow: 1">
                    <h3>Shadower</h3>
                    <p
                      class="fw-light text-body-secondary"
                      *ngIf="sessionData.shadowers.length == 0">
                      No shadower has been added to this session.
                    </p>
                    <ng-container
                      *ngFor="let shadower of sessionData.shadowers">
                      <div class="my-3">
                        <app-coaching-shadower
                          [shadower]="shadower"
                          [logId]="sessionData.id"
                          [isCoach]="isCoach || isCreator" />
                      </div>
                    </ng-container>
                    <div *ngIf="isCoach || isCreator">
                      <label class="form-label">Add Shadower</label>
                      <ng-container
                        *ngIf="
                          sessionData.attendees.length +
                            sessionData.shadowers.length <=
                            8 && sessionData.shadowers.length < 8;
                          else maxAttendees
                        ">
                        <app-user-select
                          [clearAfterSelection]="true"
                          [includeE2l]="true"
                          (selectedUser)="addShadower($event)" />
                      </ng-container>
                    </div>
                  </div>
                  <div
                    *ngIf="isCoach || isCreator"
                    class="d-flex justify-content-center justify-content-md-end">
                    <button
                      (click)="deleteSessionModal.open()"
                      aria-label="delete session button"
                      class="btn btn-secondary fs-14 fw-800 mt-5 mb-2"
                      type="button">
                      Delete Session <i class="bi bi-trash"></i>
                    </button>
                    <app-modal
                      #deleteSessionModal
                      [modalConfig]="{
                        titleText: 'Are you sure?',
                        headerClass: ['modal-header', 'bg-primary', 'text-white'],
                        customFooter: true,
                      }">
                      <button
                        class="btn btn-closemodal"
                        title="Close"
                        aria-label="close"
                        headerContent
                        (click)="deleteSessionModal.close()">
                        <i class="bi bi-x-lg text-white"></i>
                      </button>
                      <div class="container">
                        <div class="row">
                          <div
                            class="col-12 d-flex justify-content-center align-items-center">
                            <div class="my-3 fs-14">
                              <div class="fs-18 mb-2">
                                Do you want to permanently delete this session?
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="w-100 d-flex justify-content-between"
                        footerContent>
                        <button
                          (click)="deleteSessionModal.close()"
                          class="btn btn-reset">
                          Cancel
                        </button>
                        <button
                          (click)="deleteSession(); deleteSessionModal.close()"
                          class="btn btn-primary">
                          Yes, delete session
                        </button>
                      </div>
                    </app-modal>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          role="button"
          (click)="toggleResources()"
          class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start resources-fade-out resources-tab {{
            resourcesTabActive ? '' : 'hide'
          }} ">
          <div class="d-flex align-items-center">
            <i class="bi bi-book resources-icon"></i>
            <p class="resources-title">Resources</p>
          </div>
        </div>
        <ng-template #maxAttendees>
          <div>Maximum number of participants reached</div>
        </ng-template>
        <app-coaching-log-resources
          (hideResourcesEvent)="toggleResources()"
          [user]="user"
          [sessionId]="sessionId"
          [resourcesActive]="resourcesActive" />
        <app-session-type-modal
          #sessionTypeModal
          [currentSession]="sessionData" />
      </ng-container>
      <ng-template #sessionErrorMessage>
        <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
          <app-error-message [message]="coachLogStatus.errors[0]" />
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="w-100" style="height: 400px">
        <app-loader />
      </div>
    </ng-template>
  </div>
</div>
