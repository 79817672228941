<div class="container">
  <app-general-header title="{{ pageTitle }}" />
  <div class="card border-0 p-3 mb-12">
    <div class="underlay" [ngStyle]="{ opacity: loading ? '0.5' : '1' }">
      <div class="row justify-content-between">
        <ng-container *ngIf="canViewUserSelect">
          <div class="col-lg-3 mb-3">
            <app-user-select
              (selectedUser)="showSelectedUserCalendar($event)"
              (handleClearClick)="clearSelectedUser()"></app-user-select>
          </div>
        </ng-container>
        <div class="col">
          <ng-container
            *ngIf="(isCoachUser || mscEnabled) && !isViewingOtherUserCalendar">
            <button
              *ngIf="isCoachUser || mscEnabled"
              type="button"
              id="calendar-create-log-button"
              class="btn btn-primary float-end mb-2"
              style="margin-left: 5px"
              (click)="createSessionModal.openModal()">
              <i class="bi bi-plus"></i> Create a Session
            </button>
          </ng-container>
        </div>
        <app-log-view-details-modal
          #logViewDetailsModal
          (logUpdated)="getSessions()"
          (duplicateSessionEvent)="
            updateDuplicateSessionDate($event);
            duplicateSessionModal.modal.open()
          "
          [user]="user"
          [coachlogId]="coachlogId" />
        <app-todo-view-details-modal #todoViewDetailsModal [todo]="todo" />
        <app-create-session-modal
          #createSessionModal
          [selectedStartTime]="selectedStartTime"
          [selectedEndTime]="selectedEndTime"
          [selectedStartDate]="selectedStartDate" />
        <app-duplicate-session-modal
          #duplicateSessionModal
          [user]="user"
          [session]="duplicateSession"
          (sessionDuplicated)="logViewDetailsModal.closeModal()" />
      </div>
      <div class="row">
        <div class="col">
          <full-calendar
            [options]="calendarOptions"
            [events]="calendarEvents"
            #eventCalendar></full-calendar>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="loading">
      <app-loader text=""></app-loader>
    </div>
  </div>
</div>
