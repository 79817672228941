import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { numberFromNumberOrString } from 'src/app/common/utilities/type-helpers';
import { CreateEditPlanModalComponent } from '../../shared/components/modals/create-edit-plan-modal/create-edit-plan-modal.component';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import {
  SortEvent,
  SortableHeader,
} from '../../shared/directives/sortable-header.directive';
import { CopyPlanPayload } from '../../shared/dtos/plans.dto';
import { PlansService } from '../../shared/services/plans/plans.service';
import {
  GetAllPlansAPIResponse,
  PlansAPIResponse,
} from '../../shared/types/responses/plan.responses';
import { PlanSearchParams, checkIfPlanEditor } from './plans-page-helpers';

@Component({
  selector: 'app-plans-page',
  templateUrl: './plans-page.component.html',
  styleUrl: './plans-page.component.scss',
})
export class PlansPageComponent implements OnInit {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('createEditPlanModal')
  createEditPlanModal: CreateEditPlanModalComponent;

  @ViewChild('copyPlanModal') copyPlanModal: ModalComponent;

  user: User;

  planEditor = false;

  isB2b = false;

  tableData: PlansAPIResponse[];

  plansLoading = true;

  searchTerm: string;

  searchTimeout: ReturnType<typeof setTimeout>;

  plansMeta: PaginationDTO;

  loadingIcons = true;

  currentEditPlan: PlansAPIResponse | null;

  planToCopy: PlansAPIResponse;

  copyFormSubmitted = false;

  copyDates = true;

  copyPlanStartDate: NgbDate | null;

  copyPlanEndDate: NgbDate | null;

  copyPlanSaveError = false;

  userRoleDict = UserRole;

  isE2lUser = false;

  submitLoading = false;

  copyPlanInfo: CopyPlanPayload = {
    title: '',
    district_id: 0,
    copy_dates: 1,
    start_date: '',
    end_date: '',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    title: 'gray',
    description: 'gray',
    start_date: 'gray',
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  searchParams: PlanSearchParams = {
    title: '',
    page: 1,
    sort_by: '',
    sort_by_desc: false,
    expand: 'plan_status, district',
  };

  clearIndex = 0;

  constructor(
    private plansService: PlansService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getPlans();
    numberFromNumberOrString('10');

    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    if (this.user) {
      this.planEditor = checkIfPlanEditor(this.user);
      this.isB2b = checkIfB2b(this.user);
      this.isE2lUser = checkIfE2L(this.user);
    }
  }

  getPlans() {
    this.plansLoading = true;
    this.searchParams.title = this.searchTerm ? this.searchTerm : '';
    this.plansService
      .getPlans(this.searchParams)
      .subscribe((res: GetAllPlansAPIResponse) => {
        if (res) {
          if (res.items) {
            this.tableData = res.items;
            this.plansMeta = res._meta;
            this.plansLoading = false;
            this.updateColumns();
          }
        }
      });
  }

  searchTermChanged(event: Event) {
    this.searchTerm = (<HTMLInputElement>event.target).value;
    this.searchTopicsDebounce();
  }

  searchTopicsDebounce() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchParams.page = 1;
      this.getPlans();
    }, 300);
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.searchParams.sort_by = '';
      this.searchParams.sort_by_desc = '';
    } else {
      this.searchParams.sort_by = column;
      if (direction === 'asc') {
        this.searchParams.sort_by_desc = true;
      } else if (direction === 'desc') {
        this.searchParams.sort_by_desc = false;
      }
    }
    this.sortInfo.column = column;
    this.sortInfo.direction = direction;
    this.loadingIcons = true;
    this.searchParams.page = 1;
    this.getPlans();
  }

  paginationChanges(pageNumber?: number) {
    this.searchParams = pageNumber
      ? {
          ...this.searchParams,
          page: pageNumber,
        }
      : this.searchParams;
    this.getPlans();
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });
    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  openCreateEditPlanModal(plan?: PlansAPIResponse) {
    if (plan) {
      this.currentEditPlan = plan;
      this.createEditPlanModal.createEditPlanModal.modalConfig.titleText =
        'Edit Implementation Plan';
    } else {
      this.createEditPlanModal.createEditPlanModal.modalConfig.titleText =
        'Create Implementation Plan';
    }
    this.createEditPlanModal.createEditPlanModal.loadConfig();
    this.createEditPlanModal.createEditPlanModal.open();
  }

  openCopyPlanModal(plan: PlansAPIResponse) {
    this.planToCopy = plan;

    this.copyPlanInfo.title = `${plan.title} (Copy)`;

    this.copyPlanModal.open();
  }

  copyPlan(copyPlanInfo: CopyPlanPayload) {
    this.copyFormSubmitted = true;
    this.submitLoading = true;

    if (!this.copyDates && this.copyPlanStartDate && this.copyPlanEndDate) {
      this.copyPlanInfo.start_date = `${this.copyPlanStartDate.year}-${this.copyPlanStartDate.month}-${this.copyPlanStartDate.day}`;
      this.copyPlanInfo.end_date = `${this.copyPlanEndDate.year}-${this.copyPlanEndDate.month}-${this.copyPlanEndDate.day}`;
    }

    if (
      !this.isE2lUser &&
      copyPlanInfo.district_id === 0 &&
      this.user.district
    ) {
      copyPlanInfo.district_id = this.user.district.id;
    }

    if (
      (copyPlanInfo.title && this.copyDates) ||
      (this.copyPlanStartDate && this.copyPlanEndDate)
    ) {
      copyPlanInfo.copy_dates = this.copyDates ? 1 : 0;

      this.plansService.copyPlan(this.planToCopy.id, copyPlanInfo).subscribe({
        error: () => {
          this.copyPlanSaveError = true;
          this.submitLoading = false;
        },
        next: (res) => {
          this.copyFormSubmitted = false;
          this.submitLoading = false;
          this.copyPlanModal.close();

          if (res.item) {
            this.router.navigate([
              `/plans/implementation-plan/${res.item.id}/details`,
            ]);
          }
        },
      });
    }
  }

  closeCopyPlanModal() {
    this.resetCopyPlanModal();
    this.copyPlanSaveError = false;
    this.copyPlanModal.close();
  }

  resetCopyPlanModal() {
    this.copyPlanInfo.title = '';
    this.copyPlanInfo.district_id = 0;
    this.copyDates = true;
    this.copyPlanStartDate = null;
    this.copyPlanEndDate = null;
    this.clearIndex += 1;
  }

  copyPlanSelectDistrict(district?: DistrictSimpleDto | null) {
    if (district) {
      this.copyPlanInfo.district_id = district.id;
    }
  }

  handleCopyDates() {
    if (!this.copyDates) {
      if (!this.copyPlanStartDate && !this.copyPlanEndDate) {
        const startDateArr = this.planToCopy.start_date.split('-');
        const endDateArr = this.planToCopy.end_date.split('-');

        this.copyPlanStartDate = new NgbDate(
          parseInt(startDateArr[0], 10),
          parseInt(startDateArr[1], 10),
          parseInt(startDateArr[2], 10)
        );

        this.copyPlanEndDate = new NgbDate(
          parseInt(endDateArr[0], 10),
          parseInt(endDateArr[1], 10),
          parseInt(endDateArr[2], 10)
        );
      }
    }
  }
}
