<div class="container">
  <app-general-header title="Coaching Session"></app-general-header>
  <div class="card border-0">
    <div class="card-body p-3 p-lg-5 pt-lg-4">
      <ng-container *ngIf="sessionLoaded; else loadingTemplate">
        <ng-container *ngIf="sessionData; else sessionErrorMessage">
          <div class="session-nav-bar">
            <a
              routerLink="/general/log/{{ sessionId }}/info"
              class="text-decoration-none text-body-secondary">
              < Back to Session Info
            </a>
          </div>
          <h2 class="fs-1 fw-bold text-center my-3">
            {{ sessionData.title }}
          </h2>
          <div
            class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex flex-row align-items-center">
              <div class="me-3 text-body-secondary">Current Viewers</div>
              <app-avatar
                style="margin-left: -5px"
                avatarSize="small"
                *ngFor="let attendee of pusherAttendees"
                [userId]="attendee.id"
                [avatarData]="attendee.avatar"
                [grayScale]="!attendee.samePage"
                data-testid="attendee-avatar"></app-avatar>
            </div>
            <button routerLink="/coaching" class="btn btn-primary btn-next">
              Finish
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
          <div class="row">
            <ng-container *ngIf="sessionData">
              <div class="col-lg-7 mb-5">
                <h3 class="fw-800">Meeting Notes</h3>
                <app-wysiwyg-editor
                  #resourceeditor
                  [isSaving]="isSaving"
                  [version]="'full'"
                  [config]="editorConfig"
                  [editorContent]="initialContent"
                  (editorContentEvent)="updateNotes($event)" />
              </div>
              <div class="col-lg-5">
                <app-action-plan [coachlogId]="sessionId"></app-action-plan>
                <div class="mt-5 mb-3">
                  <h3 class="fw-800">Attachments</h3>
                  <div class="p-3" style="background: #efebf1">
                    <app-file-upload
                      #uploadComponent
                      (outputFiles)="uploads.emit($event)"
                      [uploadType]="uploadType" />
                    <div class="p-2 mt-3">
                      <app-file-list
                        [uploadType]="uploadType"
                        *ngIf="attachments"
                        [fileList]="attachments"
                        [canDelete]="true"
                        [logId]="sessionId"></app-file-list>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            role="button"
            (click)="toggleResources()"
            class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start resources-fade-out resources-tab {{
              resourcesTabActive ? '' : 'hide'
            }} ">
            <div class="d-flex align-items-center">
              <i class="bi bi-book resources-icon"></i>
              <p class="resources-title">Resources</p>
            </div>
          </div>
          <app-coaching-log-resources
            (hideResourcesEvent)="toggleResources()"
            [user]="user"
            [sessionId]="sessionId"
            [resourcesActive]="resourcesActive" />
        </ng-container>
        <ng-template #sessionErrorMessage>
          <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
            <app-error-message [message]="coachLogStatus.errors[0]" />
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100" style="height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
