import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Channel, PresenceChannel } from 'pusher-js';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  map,
} from 'rxjs';
import {
  CoachlogSearchPayloadDTO,
  CoachlogSearchPayloadItemsDTO,
} from 'src/app/common/dtos/coachlog-search-payload.dto';
import {
  AddCompetencyPayload,
  ChangeTypePayload,
  CreateOptionPayload,
  CreateWillPayload,
  FeedbackPayload,
  GetCoachLogTypesPayload,
  UpdateAttendeePayload,
  UpdateLogPayload,
  UpdateShadowerPayload,
  willPayloadToAPIPayload,
} from 'src/app/private/shared/types/payloads/coaching-log.payloads';

import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import { AvatarDTO } from '../../components/avatar/avatar.dto';
import { OptionDTO, WillDTO } from '../../dtos/attendee-rubric.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
  CoachingSessionShadowerDTO,
  CoachingSessionTypeDTO,
  NewCoachingSessionItemsPayloadDTO,
  SmartCoachingSessionDTO,
} from '../../dtos/coaching-session.dto';
import { CreatedCoachingLogDTO } from '../../dtos/created-coaching-log.dto';
import { ResourceListItemDTO } from '../../dtos/resources.dto';
import {
  assessmentsDTOFromAPIResponse,
  attendeeRubricsDTOFromAPIResponse,
  createOptionPayloadToCreateOptionAPIPayload,
  goalDTOFromAPIResponse,
  rubricDTOFromAPIResponse,
} from '../../helpers/translators/attendee-rubrics-dto.translator';
import {
  badgeUserListDTOFromAPIResponse,
  coachlogSearchPayloadItemsFromComponent,
  feedbackListDTOFromAPIResponse,
  individualCoachingSessionDTOFromAPIResponse,
  nextSessionDTOFromAPIResponse,
  upcomingCoachingSessionsDTOFromAPIResponse,
} from '../../helpers/translators/coaching-session-dto.translator';
import {
  createdCoachingLogDTOFromAPIResponse,
  newCoachingSessionPayloadDtoFromComponent,
  newSmartSessionPayloadDtoFromComponent,
} from '../../helpers/translators/created-coaching-log-dto.translator';
import {
  UpdateGoal,
  UpdateNotes,
} from '../../state/coaching-log/coaching-log.actions';
import { SetGoalPayload } from '../../types/payloads/set-goal.payload';
import {
  AssessmentAPIResponse,
  AttendeeRubricsAPIResponse,
  BadgeUserAPIResponse,
  CreatedCoachingLogAPIResponse,
  FeedbackAPIResponse,
  GoalAPIResponse,
  IndividualCoachingSessionDataAPIResponse,
  RubricAPIResponse,
  UpcomingCoachingSessionsDataAPIResponse,
} from '../../types/responses/coaching-log.responses';
import { UserPusherService } from '../pusher/user.pusher.service';
import { CoachingLogAttendancePusherService } from './coaching-log-attendance-pusher.service';
import { CoachingLogPusherService } from './coaching-log-pusher.service';
import { IndicatorAssessmentPayload } from './coaching-log-service.dto';
import { convertFromSetGoalPayload } from './coaching-log-service.translators';

const blankPayload: CoachlogSearchPayloadDTO = {
  keywords: '',
  from_date: 0,
  to_date: 0,
  district_id: 0,
  competencies: [],
  schools: [],
  coach_user_id: [],
  attendees: [],
  logtype: 0,
  per_page: 10,
  sort_order: 'DESC',
  own_only: 0,
};

const blankItemsPayload: CoachlogSearchPayloadItemsDTO = {
  keywords: '',
  from_date: 0,
  to_date: 0,
  competency_items: [],
  school_items: [],
  coach_items: [],
  coachee_items: [],
  per_page: 10,
  sort_order: 'DESC',
  own_only: 0,
  page: 1,
};

@Injectable({ providedIn: 'root' })
export class CoachingLogService {
  private searchFiltersData: CoachlogSearchPayloadDTO = deepCopy(blankPayload);

  private searchFiltersSource = new BehaviorSubject(this.searchFiltersData);

  public searchFiltersObs = this.searchFiltersSource.asObservable();

  public currentPage: string;

  private searchFiltersItemsData: CoachlogSearchPayloadItemsDTO =
    deepCopy(blankItemsPayload);

  blankSearchOptions: CoachlogSearchPayloadDTO = deepCopy(blankPayload);

  blankSearchOptionsItems: CoachlogSearchPayloadItemsDTO =
    deepCopy(blankItemsPayload);

  private searchFiltersItemsSource = new BehaviorSubject(
    this.searchFiltersItemsData
  );

  public searchFiltersItemsObs = this.searchFiltersItemsSource.asObservable();

  public channel: PresenceChannel;

  public pusherAttendees: AvatarDTO[];

  public coachlogSearchOptionItems: CoachlogSearchPayloadItemsDTO =
    deepCopy(blankItemsPayload);

  public coachlogCreated: ReplaySubject<boolean> = new ReplaySubject(1);

  public coachlogDuplicated: ReplaySubject<boolean> = new ReplaySubject();

  public smartCoachlogCreated: ReplaySubject<SmartCoachingSessionDTO> =
    new ReplaySubject(1);

  public coachlogError: Subject<string> = new Subject();

  public coachlogDeleted: ReplaySubject<CoachingSessionDTO> = new ReplaySubject(
    1
  );

  public attendeeAdded: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private apiService: APICoreService,
    private router: Router,
    private store: Store,
    public logPusherService: CoachingLogPusherService,
    public attendancePusherService: CoachingLogAttendancePusherService,
    private userPusherService: UserPusherService
  ) {}

  createLog(
    coach_id: number,
    coachee_ids: number[],
    shouldRedirect: boolean
  ): Observable<CreatedCoachingLogDTO | null> {
    return this.apiService
      .postRequest('growelab/coaching/quick-start', {
        coach_id,
        coachee_ids,
      })
      .pipe(
        map((res) => {
          const responseDTO = createdCoachingLogDTOFromAPIResponse(
            res as CreatedCoachingLogAPIResponse
          );
          if (res.item.id && shouldRedirect) {
            this.router.navigate([`/coaching/log/${res.item.id}/info`]);
          }
          return responseDTO;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getLogTypes(districtId: number): Observable<CoachingSessionTypeDTO[] | null> {
    return this.apiService
      .getRequest(
        `egrowe/coachlogs/types?per_page=100&district_id=${districtId}`
      )
      .pipe(map((response) => response.items));
  }

  getPreviousLogs(
    attendeeId: number,
    startDate: number,
    logType: number,
    perPage = 50
  ): Observable<CoachingSessionDTO[] | null> {
    return this.apiService
      .getRequest(
        `egrowe/coachlogs?to_date=${startDate}&page=1&per_page=${perPage}&logtype=${logType}&attendees[0]=${attendeeId}`
      )
      .pipe(map((response) => response.items));
  }

  disconnectFromPusherChannel(id: number) {
    this.logPusherService.removeChannel(`coachlog-v2-${id}`);

    if (this.currentPage) {
      this.disconnectFromPusherPageChannel(id, this.currentPage);
      // Clear current page after disconnecting so that we don't reconnect unintentionally
      this.currentPage = '';
    }
  }

  connectToPusherChannel(id: number) {
    this.logPusherService.addChannel(`coachlog-v2-${id}`);

    if (this.currentPage) {
      this.connectToPusherPageChannel(id, this.currentPage);
    }
  }

  disconnectFromPusherPageChannel(id: number, page: string) {
    this.attendancePusherService.removeChannel(`coachlog-v2-${id}-${page}`);
  }

  connectToPusherPageChannel(id: number, page: string) {
    this.attendancePusherService.addChannel(`coachlog-v2-${id}-${page}`);
  }

  getChannel(id: number): Channel {
    return this.logPusherService.getChannel(`coachlog-v2-${id}`);
  }

  updateNotes(logId: number, content: string) {
    return (
      this.apiService
        .putRequest(`egrowe/coachlog-v2/${logId}/notes`, {
          content,
        })
        .subscribe(() =>
          this.store.dispatch(
            new UpdateNotes({ notes: content, coachlogId: logId })
          )
        ),
      catchError((err) => {
        throw err;
      })
    );
  }

  getCoachingSession(
    id: number,
    omitConnectingToPusher = false
  ): Observable<CoachingSessionDTO> {
    if (!omitConnectingToPusher) {
      this.connectToPusherChannel(id);
    }

    return this.apiService.getRequest(`egrowe/coachlogs-v2/${id}`).pipe(
      map((res) => {
        const responseDTO = individualCoachingSessionDTOFromAPIResponse(
          res as IndividualCoachingSessionDataAPIResponse
        );
        return responseDTO;
      })
    );
  }

  getAttendeeRubrics(id: number) {
    return this.apiService
      .getRequest(`growelab/coaching/${id}/attendee-rubrics`)
      .pipe(
        map((res) => {
          const responseDTO = attendeeRubricsDTOFromAPIResponse(
            res as AttendeeRubricsAPIResponse
          );
          Object.keys(responseDTO.decomposedByUser).forEach((userId) => {
            this.userPusherService.addChannel(`growth-${userId}`);
          });
          return responseDTO;
        })
      );
  }

  disconnectFromAttendeePusherChannels(id: number) {
    this.apiService
      .getRequest(`growelab/coaching/${id}/attendee-rubrics`)
      .subscribe((res) => {
        if (res.items) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.items.forEach((attendee: any) => {
            this.userPusherService.removeChannel(`growth-${attendee.user_id}`);
          });
        }
      });
  }

  assessIndicator(payload: IndicatorAssessmentPayload) {
    const postData: IndicatorAssessmentPayload = {
      ...payload,
    };

    return this.apiService
      .postRequest('egrowe/indicator-assessments', postData)
      .pipe(
        map((res) => {
          const responseDTO = assessmentsDTOFromAPIResponse(
            res.item as AssessmentAPIResponse
          );
          return responseDTO;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  deleteAssessment(assessmentId: number, logId?: number) {
    return this.apiService
      .deleteRequest(
        logId
          ? `egrowe/indicator-assessments/${assessmentId}/${logId}`
          : `egrowe/indicator-assessments/${assessmentId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          throw err;
        })
      );
  }

  getUpcomingSessions(
    dateRange = 7,
    isSmart = 0
  ): Observable<[CoachingSessionDTO[], PaginationDTO]> {
    // default daterange is 7 unless otherwise specified
    // isSmart = 0 returns all sessions, isSmart = 1 returns only msc sessions
    let currentDate: Date | number = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate = currentDate.getTime() / 1000;

    const twelveHours = 3600 * 12;
    const numberOfDays = 86400 * dateRange;

    const requestOptions = {
      keywords: '',
      to_date: new Date().getTime() / 1000 + twelveHours + numberOfDays,
      competency_items: [],
      school_items: [],
      coach_items: [],
      coachee_items: [],
      per_page: 50,
      sort_order: 'ASC',
      own_only: 1,
      from_date: currentDate,
      is_smart: isSmart,
    };
    this.setFilters(requestOptions);
    return this.getSessions();
  }

  getSession(sessionId: number) {
    return this.apiService.getRequest(`egrowe/coachlogs-v2/${sessionId}`);
  }

  getSessions(
    include_private_logs = 0
  ): Observable<[CoachingSessionDTO[], PaginationDTO]> {
    const filterFreezeValues: CoachlogSearchPayloadDTO =
      coachlogSearchPayloadItemsFromComponent(
        this.searchFiltersItemsSource.value
      );
    filterFreezeValues.include_private_logs = include_private_logs;
    return this.apiService
      .getRequest('egrowe/coachlogs', filterFreezeValues)
      .pipe(
        map((res) => {
          const responseDTO = upcomingCoachingSessionsDTOFromAPIResponse(
            res as UpcomingCoachingSessionsDataAPIResponse
          );
          const responseMeta = res._meta;
          return [responseDTO, responseMeta];
        })
      );
  }

  setFilters(filters: CoachlogSearchPayloadItemsDTO) {
    this.searchFiltersItemsSource.next(filters);
  }

  resetFilters() {
    this.searchFiltersItemsSource.next(this.blankSearchOptionsItems);
  }

  setGoal(payload: SetGoalPayload) {
    return (
      this.apiService
        .postRequest('egrowe/goals', convertFromSetGoalPayload(payload))
        .subscribe((res) => {
          const goalDTO = goalDTOFromAPIResponse(res.item as GoalAPIResponse);
          this.store.dispatch(new UpdateGoal(goalDTO));
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  createCoachinglog(payloadItems: NewCoachingSessionItemsPayloadDTO) {
    let newPayload = newCoachingSessionPayloadDtoFromComponent(payloadItems);
    newPayload = deepCopy(newPayload);

    return (
      this.apiService.postRequest('egrowe/coachlogs', newPayload).subscribe(
        (res) => {
          if (res) {
            if (newPayload.datetimes) {
              this.coachlogDuplicated.next(true);
            } else {
              this.coachlogCreated.next(true);
            }
            this.coachlogError.next('');
          }
        },
        (err) => {
          this.coachlogError.next(err.error.message);
        }
      ),
      catchError((err) => {
        throw err;
      })
    );
  }

  createQuickWalkSession(payloadItems: NewCoachingSessionItemsPayloadDTO) {
    let newPayload = newCoachingSessionPayloadDtoFromComponent(payloadItems);
    newPayload = deepCopy(newPayload);
    newPayload = {
      ...newPayload,
      is_present: 1,
    };
    return this.apiService.postRequest('egrowe/coachlogs', newPayload);
  }

  changeType(payloadItems: ChangeTypePayload) {
    this.apiService
      .postRequest('egrowe/coachlogs/change-type', payloadItems)
      .subscribe();
  }

  createSmartlog(payloadItems: NewCoachingSessionItemsPayloadDTO) {
    const newPayload = newSmartSessionPayloadDtoFromComponent(payloadItems);
    return (
      this.apiService.postRequest('egrowe/smartlog', newPayload).subscribe(
        (res) => {
          if (res) {
            this.coachlogCreated.next(true);
            this.smartCoachlogCreated.next(res);
            this.coachlogError.next('');
          }
        },
        (err) => {
          this.coachlogError.next(err.error.message);
        }
      ),
      catchError((err) => {
        throw err;
      })
    );
  }

  getSmartLogs(userId: number) {
    return this.apiService.getRequest(
      `egrowe/coachlogs?attendee_user_id=${userId}&sort_order=ASC&is_smart=1`
    );
  }

  createOption(coachlogId: number, options: CreateOptionPayload) {
    return (
      this.apiService
        .postRequest(
          `egrowe/coachlogs/${coachlogId}/options`,
          createOptionPayloadToCreateOptionAPIPayload(options)
        )
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  copyOption(
    coachlogId: number,
    options: CreateOptionPayload,
    resources: ResourceListItemDTO[]
  ) {
    return (
      this.apiService
        .postRequest(
          `egrowe/coachlogs/${coachlogId}/options`,
          createOptionPayloadToCreateOptionAPIPayload(options)
        )
        .subscribe((res) => {
          if (res) {
            resources.forEach((resource) => {
              this.addResourceToOption(resource, res.item.id);
            });
            this.addCompetency(
              {
                egrowe_rubric_id: options.groweRubricId,
                egrowe_standard_id: options.groweCompetencyId,
              },
              coachlogId
            ).subscribe();
          }
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  updateOption(optionDTO: OptionDTO) {
    return (
      this.apiService
        .putRequest(`egrowe/coachlogs/options/${optionDTO.id}`, optionDTO)
        .subscribe(),
      catchError((err) => {
        // This error would be important, as the frontend will not reflect that the data wasn't saved
        throw err;
      })
    );
  }

  deleteOption(optionId: number) {
    return (
      this.apiService
        .deleteRequest(`egrowe/coachlogs/options/${optionId}`)
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  addResourceToOption(resource: ResourceListItemDTO, optiondId: number) {
    return (
      this.apiService
        .postRequest(`egrowe/coachlogs/options/${optiondId}/resources`, {
          elearn_resource_id: resource.id,
        })
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  removeResourceFromOption(resourceId: number, optiondId: number) {
    return (
      this.apiService
        .deleteRequest(
          `egrowe/coachlogs/options/${optiondId}/resources/${resourceId}`
        )
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  createWill(payload: CreateWillPayload) {
    return (
      this.apiService
        .postRequest('egrowe/coachlogs/wills', willPayloadToAPIPayload(payload))
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  deleteWill(willId: number) {
    return (
      this.apiService
        .deleteRequest(`egrowe/coachlogs/wills/${willId}`)
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  updateWill(payload: WillDTO) {
    return (
      this.apiService
        .putRequest(
          `egrowe/coachlogs/wills/${payload.id}`,
          willPayloadToAPIPayload(payload)
        )
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  getCompetencies(logId: number) {
    return this.apiService.getRequest(
      `growelab/coaching/${logId}/competencies`
    );
  }

  addCompetency(payload: AddCompetencyPayload, logId: number) {
    return this.apiService.postRequest(
      `egrowe/coachlogs/${logId}/standards`,
      payload
    );
  }

  deleteCompetency(standardId: number, logId: number) {
    return this.apiService.deleteRequest(
      `egrowe/coachlogs/${logId}/standards?egrowe_standard_id=${standardId}`
    );
  }

  // used by coaching-session-list
  deleteCoachlog(session: CoachingSessionDTO) {
    return this.apiService
      .deleteRequest(`egrowe/coachlogs/${session.id}`)
      .subscribe((res) => {
        if (res) {
          this.coachlogDeleted.next(session);
        }
      });
  }

  // used by session
  deleteCurrentSession(sessionId: number) {
    return this.apiService.deleteRequest(`egrowe/coachlogs/${sessionId}`);
  }

  // used by coaching-session-list
  deleteMSCSession(session: CoachingSessionDTO) {
    return this.apiService
      .deleteRequest(`growelab/smartlog/${session.id}`)
      .subscribe((res) => {
        if (res) {
          this.coachlogDeleted.next(session);
        }
      });
  }

  // used by session
  deleteCurrentMSCSession(session: CoachingSessionDTO) {
    return this.apiService.deleteRequest(`growelab/smartlog/${session.id}`);
  }

  updateCoachingLog(coachingLogId: number, payload: UpdateLogPayload) {
    return (
      this.apiService
        .putRequest(`egrowe/coachlogs-v2/${coachingLogId}`, payload)
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  updateSmartLogTitle(logId: number, updatedText: string) {
    return (
      this.apiService
        .putRequest(`egrowe/smartlog/${logId}`, { title: updatedText })
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  updateSmartLogDate(logId: number, payload: number) {
    return (
      this.apiService
        .putRequest(`egrowe/smartlog/${logId}`, { log_date: payload })
        .subscribe(),
      catchError((err) => {
        throw err;
      })
    );
  }

  getCoachlogTypes(districtId?: number) {
    const payload: GetCoachLogTypesPayload = { per_page: 100 };
    if (districtId) {
      payload.district_id = districtId;
    }
    return this.apiService.getRequest('egrowe/coachlogs/types', payload).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }

  addAttendee(userId: number, coachlogId?: number) {
    return (
      this.apiService
        .postRequest(`egrowe/coachlogs/${coachlogId}/attendees`, {
          user_id: userId,
        })
        .subscribe((res) => {
          if (res) {
            this.attendeeAdded.next(true);
          }
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  deleteAttendee(attendee: CoachingSessionAttendeeDTO) {
    return this.apiService.deleteRequest(
      `egrowe/coachlogs/attendees/${attendee.id}`
    );
  }

  updateAttendee(attendeeId: number, changes: UpdateAttendeePayload) {
    return this.apiService.putRequest(
      `egrowe/coachlogs/attendees/${attendeeId}`,
      changes
    );
  }

  getAbsentReasons() {
    return this.apiService
      .getRequest('egrowe/coachlogs/absent-reasons?per-page=100')
      .pipe(
        map((res) => res),
        catchError((err) => {
          throw err;
        })
      );
  }

  addShadower(userId: number, coachlogId?: number) {
    return (
      this.apiService
        .postRequest(`egrowe/coachlogs/${coachlogId}/shadowers`, {
          user_id: userId,
        })
        .subscribe((res) => {
          if (res) {
            this.attendeeAdded.next(true);
          }
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  deleteShadower(shadower: CoachingSessionShadowerDTO) {
    return this.apiService.deleteRequest(
      `egrowe/coachlogs/shadowers/${shadower.id}`
    );
  }

  updateShadower(
    attendeeId: number,
    logId: number,
    changes: UpdateShadowerPayload
  ) {
    return this.apiService.putRequest(
      `egrowe/coachlogs/${logId}/shadowers/${attendeeId}`,
      changes
    );
  }

  getFeedback(logId: number) {
    return this.apiService
      .getRequest(`egrowe/coachlog/feedback/by-coachlog/${logId}`)
      .pipe(
        map((res) =>
          feedbackListDTOFromAPIResponse(
            res as { items: FeedbackAPIResponse[] }
          )
        )
      );
  }

  getBadgeUsers(logId: number) {
    return this.apiService
      .getRequest(`growelab/coachlogs/${logId}/badges-earned`)
      .pipe(
        map((res) =>
          badgeUserListDTOFromAPIResponse(
            res as { items: BadgeUserAPIResponse[] }
          )
        )
      );
  }

  getAttendeesNeedNextLog(logId: number) {
    return this.apiService
      .getRequest(`egrowe/coachlogs/${logId}/attendees-need-next-log`)
      .pipe(
        map((res) => {
          if (res.attendeesScheduled[0]) {
            res.attendeesScheduled[0].nextLog = nextSessionDTOFromAPIResponse(
              res.attendeesScheduled[0].nextLog
            );
          }
          return res;
        })
      );
  }

  submitFeedback(payload: FeedbackPayload) {
    return this.apiService.postRequest('egrowe/coachlog/feedback', payload);
  }

  editFeedback(itemId: number, changedContent: string) {
    return this.apiService.putRequest(`egrowe/coachlog/feedback/${itemId}`, {
      content: changedContent,
    });
  }

  deleteFeedback(itemId: number) {
    return this.apiService.deleteRequest(`egrowe/coachlog/feedback/${itemId}`);
  }

  getSessionPercentage(sessionId: number) {
    return this.apiService.getRequest(
      `growelab/coaching/${sessionId}/update-session-percent`
    );
  }

  getCompetencyRubric(userId: number, competencyId: number) {
    return this.apiService
      .getRequest(`growelab/user/${userId}/competency/${competencyId}`)
      .pipe(
        map((res) => {
          const responseDTO = rubricDTOFromAPIResponse(
            res.rubrics as RubricAPIResponse,
            0
          );
          return responseDTO;
        })
      );
  }

  rateSession(attendeeId: number, payload: { rating: number }) {
    return this.apiService.putRequest(
      `egrowe/coachlogs/setratings/${attendeeId}`,
      payload
    );
  }

  setConfidence(attendeeId: number, payload: { self_rating: number }) {
    return this.apiService.putRequest(
      `egrowe/coachlog/setconfidence/${attendeeId}`,
      payload
    );
  }

  getReflectionData(coachId: number) {
    return this.apiService.getRequest(
      `egrowe/coachlog/reflection/coach/${coachId}`
    );
  }

  getResourcesForSession(sessionId: number) {
    return this.apiService.getRequest(
      `egrowe/coachlogs/${sessionId}/resources?expand=endorsements`
    );
  }

  addResourceToSession(resourceId: number, sessionId: number) {
    return this.apiService.postRequest(
      `egrowe/coachlogs/${sessionId}/resources`,
      {
        elearn_resource_id: resourceId,
      }
    );
  }

  deleteResourceFromSession(resourceId: number, sessionId: number) {
    return this.apiService.deleteRequest(
      `egrowe/coachlogs/${sessionId}/resources/${resourceId}`
    );
  }
}
