<div class="card border-0 mt-4">
  <ng-container *ngIf="dashboardInfo; else placeHolder">
    <div class="card-body">
      <div class="row fw-800 mb-2">
        <div class="col-8 col-md-5">{{ dashboardInfo.phase_displayname }}</div>
        <div class="col-4 col-md-2">Status</div>
        <div class="col-12 col-md-5">
          {{ dashboardInfo.deliverable_displayname }} Progress
        </div>
      </div>
      <ng-container
        *ngFor="let phase of dashboardInfo.phases; let isFirst = first">
        <div class="purple-border {{ !isFirst ? 'my-3' : 'mb-3' }}"></div>
        <div class="row align-items-center">
          <div class="col-8 col-md-5">{{ phase.title }}</div>
          <div class="col-4 col-md-2">
            <span class="status-pill {{ phase.status | lowercase }}">
              {{ phase.status }}
            </span>
          </div>
          <div class="col-12 col-md-5 mt-2 mt-md-0 align-items-center">
            <app-plan-progress-bar [progress]="phase.deliverables_progress" />
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<ng-template #placeHolder>
  <div class="card-header pt-3">
    <div class="card-title placeholder-glow">
      <span class="placeholder col-5"></span>
    </div>
  </div>
  <div class="card-body pt-0" style="height: 18rem">
    <div class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
    <div class="card-text placeholder-glow mt-2">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
  </div>
</ng-template>
