/* eslint-disable no-restricted-syntax */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription, map, take } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfCreator,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';

import { UserService } from 'src/app/common/state/user/user.service';
import { Animations } from 'src/app/common/utilities/animations';
import { UserState } from '../../../common/state/user/user.state';
import { LeveledCompetencyModalComponent } from '../../shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { DatepickerOutput } from '../../shared/components/datepicker/datepicker.component';
import { getValidTimepickerValue } from '../../shared/components/datepicker/datepicker.helpers';
import { SessionTypeModalComponent } from '../../shared/components/session-type-modal/session-type-modal.component';
import { CompetencyDTO } from '../../shared/dtos/attendee-rubric.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from '../../shared/dtos/coaching-session.dto';
import { PusherAttendeeDTO } from '../../shared/dtos/pusher-user.dto';
import { checkSessionDataType } from '../../shared/helpers/coachee-log.utilities';
import { AlertService } from '../../shared/services/alert/alert.service';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { UserSearchService } from '../../shared/services/user-search/user-search.service';
import { FetchSessionData } from '../../shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../shared/state/coaching-log/coaching-log.state';
import {
  AbsentReasonsAPIResponse,
  CoachlogTypesAPIResponse,
} from '../../shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-log-info-page',
  templateUrl: './coaching-log-info-page.component.html',
  styleUrls: ['./coaching-log-info-page.component.scss'],
  animations: [Animations.delayfadeInOut, Animations.fadeInOut],
  providers: [UserSearchService],
})
export class CoachingLogInfoPageComponent implements OnInit, OnDestroy {
  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  @ViewChild('sessionTypeModal')
  sessionTypeModal: SessionTypeModalComponent;

  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  coachLogStatus$ = select(CoachingLogState.getCoachLogStatus);

  coachLogStatus: APILoadingStatus;

  sessionLoaded = false;

  competenciesLoading = true;

  user: User;

  allCompetencies: CompetencyDTO[] = [];

  competencyData$ = select(CoachingLogState.getCompetenciesByLog);

  competencyData: CompetencyDTO[] = [];

  previousSessions: CoachingSessionDTO[] | null = [];

  selectedSession: CoachingSessionDTO;

  lastSessionCompetencies: {
    userId: number;
    competencies: CompetencyDTO[];
  }[] = [];

  sessionData: CoachingSessionDTO | null;

  selectedAttendee: UserDTO | null;

  sessionAttendees: UserDTO[] = [];

  pusherAttendees: PusherAttendeeDTO[];

  subs: Subscription[] = [];

  selectedLogId: number | null;

  attendeeSelectApiCalls: Subscription | null;

  coachlogTypesList: CoachlogTypesAPIResponse[];

  absentReasonsList: AbsentReasonsAPIResponse[];

  isCoach: boolean;

  isCreator: boolean;

  isShadower: boolean;

  isTimeValid = true;

  selectedTypeId: number;

  logStart: string;

  logEnd: string;

  timezone: TimezoneLinkBack;

  latestLogId: number;

  notesActive = false;

  notesTabActive = true;

  resourcesActive = false;

  resourcesTabActive = true;

  completionActive = false;

  completionTabActive = true;

  hasPresentAttendee = false;

  districtId: number | null = null;

  includeAllB2bDistricts = false;

  percentComplete: number;

  sessionCreator: UserDTO | null;

  constructor(
    private route: ActivatedRoute,
    public coachingService: CoachingLogService,
    private router: Router,
    private store: Store,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      take(1);
      this.sessionId = parseInt(url['logId']);
      this.coachingService.currentPage = 'info';
      this.store.dispatch(new FetchSessionData(this.sessionId));
    });

    // if user is not an e2L employee, filter user select options to only users in their district
    if (this.user) {
      if (
        !this.user.roles.includes(UserRole.E2L_EMPLOYEE) &&
        this.user.district
      ) {
        this.districtId = this.user.district.id;
        if (this.user.district.isB2b === 1) {
          this.includeAllB2bDistricts = true;
        }
      }
    }

    this.subs.push(
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              checkSessionDataType(sessionData, 'coaching', this.router);

              this.userService
                .fetchUserById(this.sessionData.creatorUserId)
                .subscribe((user) => {
                  if (user) {
                    this.sessionCreator = user;
                  }
                });

              this.isCoach = checkIfCoach(this.sessionData);
              this.isCreator = checkIfCreator(this.sessionData, this.user.id);
              this.isShadower = checkIfShadower(this.sessionData);
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);

              if (this.sessionData.egroweCoachlogTypeId) {
                this.selectedTypeId = this.sessionData.egroweCoachlogTypeId;
              }

              this.logStart = getValidTimepickerValue(
                this.sessionData.startDatetime,
                this.sessionData.timezone
              );
              this.logEnd = getValidTimepickerValue(
                this.sessionData.endDatetime,
                this.sessionData.timezone
              );
              this.timezone = this.sessionData.timezone as TimezoneLinkBack;

              if (this.sessionData?.attendees?.length) {
                this.sessionAttendees = this.sessionData.attendees.map(
                  (attendee) => attendee.user
                );

                if (
                  !this.selectedAttendee ||
                  !this.sessionAttendees.find(
                    (attendee) => attendee.id === this.selectedAttendee?.id
                  )
                ) {
                  this.selectedAttendee =
                    this.sessionAttendees.find(
                      (attendee) => attendee.id === this.user.id
                    ) || this.sessionAttendees[0];
                  this.getPreviousSessions(
                    this.selectedAttendee.id,
                    this.sessionData?.startDatetime as number,
                    this.selectedTypeId
                  );
                }
              } else {
                this.selectedAttendee = null;
              }

              this.updatePercentComplete(this.sessionData.percentComplete);
            }
          })
        )
        .subscribe()
    );

    this.coachLogStatus$.subscribe((status) => {
      if (status.loaded) {
        this.coachLogStatus = status;
        this.sessionLoaded = true;
      }
    });

    this.subs.push(
      this.coachingService.getCoachlogTypes().subscribe((response) => {
        this.coachlogTypesList = response.items;
        // temp to restrict log types
        this.coachlogTypesList = this.coachlogTypesList.filter(
          (logType) => logType.title === 'Coaching Conversation'
        );
      })
    );

    this.subs.push(
      this.coachingService.getAbsentReasons().subscribe((response) => {
        this.absentReasonsList = response.items;
      })
    );

    this.subs.push(
      this.coachingService.attendancePusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );

    this.subs.push(
      this.coachingService.logPusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );
  }

  getAttendees() {
    const attendeesOnPage =
      this.coachingService.attendancePusherService.attendeeRosters[
        `${this.sessionId}-info`
      ];

    if (attendeesOnPage && attendeesOnPage.length > 0) {
      this.pusherAttendees =
        this.coachingService.logPusherService.attendeeRosters[
          this.sessionId
        ].map((sessionAttendee) => {
          const samePage = attendeesOnPage.some(
            (pageAttendee) => pageAttendee.id === sessionAttendee.id
          );
          return {
            avatar: sessionAttendee.avatar,
            samePage,
            id: sessionAttendee.id,
          };
        });
    }
  }

  onLastSessionAttendeeChange($event: UserDTO) {
    if (this.selectedAttendee) {
      this.getPreviousSessions(
        this.selectedAttendee.id,
        this.sessionData?.startDatetime as number,
        this.selectedTypeId
      );
    }
    this.getCompetencyList($event.id, this.selectedSession.id);
  }

  onLastSessionChange($event: CoachingSessionDTO) {
    if (this.selectedAttendee) {
      this.disconnectLastSessionAttendees();
      this.getCompetencyList(this.selectedAttendee.id, $event.id);
      this.latestLogId = $event.id;
    }
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    const coachlogUpdates = {
      is_private: false,
    };

    if (target?.checked) {
      coachlogUpdates.is_private = true;
    }

    if (this.sessionData) {
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  getCompetencyList(userId: number, logId: number) {
    this.competenciesLoading = true;

    if (
      this.sessionData?.attendees &&
      this.sessionData.attendees.length > 0 &&
      this.sessionData.startDatetime
    ) {
      this.attendeeSelectApiCalls = this.coachingService
        .getAttendeeRubrics(logId)
        .subscribe((res) => {
          const newCompetencies = res?.decomposedByUser[userId]?.competencies;
          this.lastSessionCompetencies = [
            {
              competencies: newCompetencies,
              userId,
            },
          ];
          this.competenciesLoading = false;
        });
    }
  }

  getPreviousSessions(
    attendeeId: number,
    sessionDate: number,
    logType: number
  ) {
    sessionDate -= 1;
    this.coachingService
      .getPreviousLogs(attendeeId, sessionDate, logType)
      .subscribe((sessions) => {
        this.previousSessions = sessions;
        if (sessions && sessions.length > 0) {
          this.latestLogId = sessions[0].id;
          this.selectedSession = sessions[0];
          this.getCompetencyList(attendeeId, this.latestLogId);
        } else {
          this.competenciesLoading = false;
        }
      });
  }

  addCoachee(newCoachee: UserLiteDTO | null) {
    if (newCoachee) {
      // check if shadower...
      const isShadower = this.sessionData?.shadowers.find(
        (item) => item.userId === newCoachee.id
      );
      const isCreator = this.sessionData?.creatorUserId === newCoachee.id;
      if (!isShadower && !isCreator) {
        this.coachingService.addAttendee(newCoachee.id, this.sessionData?.id);
      } else if (isShadower) {
        this.alertService.showAlert(
          'Selected User is already a shadower of this session'
        );
      } else if (isCreator) {
        this.alertService.showAlert(
          'The creator of the session cannot be a participant'
        );
      }
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.sessionData) {
      this.sessionData.attendees = this.sessionData.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserLiteDTO | null) {
    if (newShadower) {
      const isCoachee = this.sessionData?.attendees.find(
        (item) => item.userId === newShadower.id
      );
      const isCreator = this.sessionData?.creatorUserId === newShadower.id;
      if (!isCoachee && !isCreator) {
        this.coachingService.addShadower(newShadower.id, this.sessionData?.id);
      } else if (isCoachee) {
        this.alertService.showAlert(
          'Selected User is already a participant of this session'
        );
      } else if (isCreator) {
        this.alertService.showAlert(
          'The creator of the session cannot be a shadower'
        );
      }
    }
  }

  saveTitle() {
    if (this.sessionData) {
      const coachlogUpdates = { title: this.sessionData?.title };
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput[]) {
    const startJsTimestamp = timeUpdate[0].time / 1000;
    const endJsTimestamp = timeUpdate[1].time / 1000;
    this.logStart = getValidTimepickerValue(
      startJsTimestamp,
      timeUpdate[0].timezone
    );
    this.timezone = timeUpdate[0].timezone;
    this.logEnd = getValidTimepickerValue(
      endJsTimestamp,
      timeUpdate[0].timezone
    );
    if (this.isTimeValid) {
      this.coachingService.updateCoachingLog(this.sessionData?.id as number, {
        start_datetime: startJsTimestamp,
        end_datetime: endJsTimestamp,
        timezone: this.timezone,
      });
    }
  }

  deleteSession() {
    this.coachingService.deleteCurrentSession(this.sessionId).subscribe();
  }

  navigateNextScreen() {
    if (this.hasPresentAttendee) {
      this.router.navigate([
        `/coaching/log/${this.sessionData?.id}/competencies`,
      ]);
    }
  }

  toggleCardModal(userId: number, competencyId: number) {
    this.competencyModal.coachlogId = this.latestLogId;
    this.competencyModal.competencyId = competencyId;
    this.competencyModal.userId = userId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }

  toggleNotes(): void {
    this.notesActive = !this.notesActive;
    this.notesTabActive = !this.notesTabActive;
  }

  toggleResources(): void {
    this.resourcesActive = !this.resourcesActive;
    this.resourcesTabActive = !this.resourcesTabActive;
  }

  toggleCompletion(): void {
    this.completionActive = !this.completionActive;
    this.completionTabActive = !this.completionTabActive;
  }

  openSessionTypeModal() {
    this.sessionTypeModal.openModal();
  }

  updatePercentComplete(e: number) {
    this.percentComplete = e;
  }

  disconnectLastSessionAttendees() {
    if (this.selectedSession) {
      this.coachingService.disconnectFromAttendeePusherChannels(
        this.selectedSession.id
      );
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
    if (this.attendeeSelectApiCalls) {
      this.attendeeSelectApiCalls.unsubscribe();
    }
    this.disconnectLastSessionAttendees();
    this.coachingService.disconnectFromPusherChannel(this.sessionId);
  }
}
