<div class="resource-item">
  <div class="resource-item__type">
    <i
      [ngClass]="{
        'bi bi-lightbulb': resourceListItem.type == 'research',
        'bi bi-tools': resourceListItem.type == 'diy',
        'bi bi-people': resourceListItem.type == 'workshop',
        'bi bi-briefcase': resourceListItem.type == 'template',
        'bi bi-camera-reels': resourceListItem.type == 'video'
      }"></i>
  </div>

  <div class="resource-item__primary">
    <h3 class="resource-title">
      <a
        [attr.aria-label]="'resources/' + path"
        target="_blank"
        [href]="'resources/' + path"
        >{{ resourceListItem.title }}</a
      >
    </h3>
  </div>

  <div class="d-flex align-items-center" style="width: 60px; min-width: 60px">
    <img
      *ngIf="resourceListItem.e2lRecommended"
      ngbTooltip="e2L-Recommended"
      src="assets/e2l-logo.png"
      alt="e2l"
      class="recommended-logo ms-2" />
    <i
      *ngIf="resourceListItem.districtApproved"
      ngbTooltip="District-Approved"
      class="bi bi-building-check approved-logo color-level-4 ms-2"></i>
  </div>

  <button
    [disabled]="isLoading"
    class="btn btn-delete"
    (click)="removeResource(resourceListItem.id)"
    aria-label="delete resource button"
    type="button">
    <i class="bi bi-trash"></i>
  </button>
</div>
