import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export enum TimezoneAbbreviation {
  EST = 'US/Eastern',
  EDT = 'US/Eastern',
  CST = 'US/Central',
  CDT = 'US/Central',
  MST = 'US/Mountain',
  MDT = 'US/Mountain',
  PST = 'US/Pacific',
  PDT = 'US/Pacific',
  NONE = 'Outside US',
}

export const getTimezoneAbbreviationValue = (
  tz: TimezoneAbbreviation
): string =>
  Object.values(TimezoneAbbreviation)[
    Object.keys(TimezoneAbbreviation).indexOf(tz)
  ];

export enum TimezoneLinkBack {
  Alaska = 'US/Alaska',
  Aleutian = 'US/Aleutian',
  Arizona = 'US/Arizona',
  Central = 'US/Central',
  EastIndiana = 'US/East-Indiana',
  Eastern = 'US/Eastern',
  Hawaii = 'US/Hawaii',
  IndianaStarke = 'US/Indiana-Starke',
  Michigan = 'US/Michigan',
  Mountain = 'US/Mountain',
  Pacific = 'US/Pacific',
  Samoa = 'US/Samoa',
  GMT = 'GMT',
}

export const timezones: Timezone[] = [
  {
    label: 'Alaska Time',
    regional: 'America/Anchorage',
    linkBack: TimezoneLinkBack.Alaska,
  },
  {
    label: 'Aleutian Time',
    regional: 'America/Adak',
    linkBack: TimezoneLinkBack.Aleutian,
  },
  {
    label: 'Arizona Time',
    regional: 'America/Phoenix',
    linkBack: TimezoneLinkBack.Arizona,
  },
  {
    label: 'Central Time',
    regional: 'America/Chicago',
    linkBack: TimezoneLinkBack.Central,
  },
  {
    label: 'Indiana Time',
    regional: 'America/Indiana/Indianapolis',
    linkBack: TimezoneLinkBack.EastIndiana,
  },
  {
    label: 'Eastern Time',
    regional: 'America/New_York',
    linkBack: TimezoneLinkBack.Eastern,
  },
  {
    label: 'Hawaii Time',
    regional: 'Pacific/Honolulu',
    linkBack: TimezoneLinkBack.Hawaii,
  },
  {
    label: 'Indiana-Starke Time',
    regional: 'America/Indiana/Knox',
    linkBack: TimezoneLinkBack.IndianaStarke,
  },
  {
    label: 'Michigan Time',
    regional: 'America/Detroit',
    linkBack: TimezoneLinkBack.Michigan,
  },
  {
    label: 'Mountain Time',
    regional: 'America/Denver',
    linkBack: TimezoneLinkBack.Mountain,
  },
  {
    label: 'Pacific Time',
    regional: 'America/Los_Angeles',
    linkBack: TimezoneLinkBack.Pacific,
  },
  {
    regional: 'Pacific/Pago_Pago',
    linkBack: TimezoneLinkBack.Samoa,
    label: 'Samoa Time',
  },
];

/**
 * Replacement for moment.tz.guess()
 */
export const guessTimezone = (dateInput?: Date): TimezoneAbbreviation => {
  const dateObject = dateInput || new Date();
  if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const resolved = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = timezones.find((t) => t.regional === resolved);
    if (tz) {
      // Arizona and some counties in Indiana don't observe daylight savings time;
      // our API doesn't differentiate between ST and DT, so we have to fake it
      if (tz.label === 'Arizona Time') {
        const phoenixString = dateObject.toLocaleString('en-US', {
          timeZone: 'America/Phoenix',
        });
        const laString = dateObject.toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        });
        if (phoenixString !== laString) {
          return TimezoneAbbreviation.MST;
        }
        return TimezoneAbbreviation.PDT;
      }
      if (tz.label === 'Indiana-Starke Time') {
        const knoxString = dateObject.toLocaleString('en-US', {
          timeZone: 'America/Indiana/Knox',
        });
        const newYorkString = dateObject.toLocaleString('en-US', {
          timeZone: 'America/New_York',
        });
        if (knoxString !== newYorkString) {
          return TimezoneAbbreviation.CST;
        }
        return TimezoneAbbreviation.EDT;
      }
      return tz.linkBack as TimezoneAbbreviation;
    }
  }
  // not running in a context we concern ourselves with
  return TimezoneAbbreviation.NONE;
};
export type Timezone = {
  label: string;
  regional: string;
  linkBack: string;
};
export const getTimezoneLinkBackFromValue = (
  tzString: string
): TimezoneLinkBack =>
  Object.values(TimezoneLinkBack)[
    Object.keys(TimezoneLinkBack).indexOf(
      tzString as keyof typeof TimezoneLinkBack
    )
  ];
export const getTimezoneLinkBackFromTimezone = (
  tzString: string
): TimezoneLinkBack | undefined => {
  const entry = Object.entries(TimezoneLinkBack).find(
    ([, value]) => value === tzString
  );
  return entry
    ? TimezoneLinkBack[entry[0] as keyof typeof TimezoneLinkBack]
    : undefined;
};

export const getTimezoneLinkbackFromAbbreviation = (
  abbrev: TimezoneAbbreviation
): TimezoneLinkBack => abbrev as unknown as TimezoneLinkBack;

export const getTzRegionFromLinkBack = (tz: TimezoneLinkBack): string =>
  timezones.filter((t) => t.linkBack === tz)[0].regional;

// Used to convert timezones received from data warehouse into timezone format that API uses
export function convertTimeZone(regional: string): string {
  const convertedTimezone = timezones.find(
    (timezone) => timezone.regional === regional
  );
  if (convertedTimezone) {
    return convertedTimezone.linkBack;
  }
  return regional;
}

export const timeStructFromDate = (date: Date): NgbTimeStruct => ({
  hour: date.getHours(),
  minute: date.getMinutes(),
  second: date.getSeconds(),
});

export const timeStructFromTimestamp = (timestamp: number): NgbTimeStruct => {
  const date = new Date();
  date.setTime(timestamp * 1000);
  return timeStructFromDate(date);
};

export const toHoursAndMinutes = (totalMinutes: number | string) => {
  if (typeof totalMinutes === 'string') {
    totalMinutes = parseInt(totalMinutes);
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours === 0 ? '' : `${hours}h`}${
    minutes === 0 ? '' : ` ${minutes}m`
  }`;
};
