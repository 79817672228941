import {
  CreateActionItemResponse,
  CreateTodoAPIResponse,
  CreateTodoItemResponse,
  TodoApiResponse,
} from 'src/app/common/types/responses/responses';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import {
  ActionItemDTO,
  ToDoDTO,
} from '../../components/todos/card-todos/todo.dto';

const translateTodoFromResponseItem = (
  item: CreateTodoItemResponse
): ToDoDTO => ({
  id: item.id,
  content: item.content,
  description: item.description,
  dueDate: item.due_date,
  type: item.type,
  createdAt: item.created_at,
  updatedAt: item.updated_at,
  completed: BooleanNumber.FALSE,
  ...(item.planTodoMeta && {
    planTodoMeta: {
      planId: item.planTodoMeta.plan_id,
      deliverableId: item.planTodoMeta.deliverable_id,
      deliverableDisplayName: item.planTodoMeta.deliverable_displayname,
      actionItemId: item.planTodoMeta.action_item_id,
      actionItemDisplayName: item.planTodoMeta.actionitem_displayname,
      planTitle: item.planTodoMeta.plan_title,
    },
  }),
});

export const todoDTOFromCreateAPIResponse = (
  response: CreateTodoAPIResponse
): ToDoDTO => translateTodoFromResponseItem(response.item);

export const todoDTOFromAPIResponse = (
  response: TodoApiResponse
): ToDoDTO[] => {
  if (response.items.length === 0) {
    return [];
  }
  return response.items.map((item) => ({
    ...translateTodoFromResponseItem(item),
    completed: item.completed,
  }));
};

export const translateActionItemFromResponseItem = (
  item: CreateActionItemResponse
): ActionItemDTO => ({
  content: item.content,
  createdAt: item.created_at,
  description: item.description,
  dueDate: parseInt(item.due_date),
  id: item.id,
  type: item.type,
  updatedAt: item.updated_at,
  userTodoMeta: {
    createdAt: item.userTodoMeta.created_at,
    id: item.userTodoMeta.id,
    refTable: item.userTodoMeta.ref_table,
    refTableId: item.userTodoMeta.ref_table_id,
    updatedAt: item.userTodoMeta.updated_at,
    userId: item.userTodoMeta.user_id,
    userTodoId: item.userTodoMeta.user_todo_id,
  },
  userId: parseInt(item.user_id),
});

export const actionItemsDTOFromAPIResponse = (response: {
  items: CreateActionItemResponse[];
}) => response.items.map((item) => translateActionItemFromResponseItem(item));
