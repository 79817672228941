<div
  tabindex="0"
  class="resources-fade resources-container {{
    resourcesActive ? 'show' : ''
  }}">
  <div
    class="resources-title text-center d-flex align-items-center justify-content-between">
    <div class="ps-0 text-start"></div>
    <div>Resources</div>
    <div class="text-end pe-0">
      <i
        (click)="toggleResources()"
        title="hide-icon"
        role="button"
        class="bi bi-arrow-bar-right me-2 p-2"></i>
    </div>
  </div>
  <div class="resources-body">
    <app-select-resource
      [clearAfterSelect]="true"
      [userDistrictId]="userDistrictId"
      [placeholder]="'Search for Resources'"
      (afterSelection)="addResource($event)"></app-select-resource>
    <div class="resources-list mt-3">
      <ng-container *ngIf="resources; else loadingTemplate">
        <ng-container *ngIf="resources.length > 0; else noResources">
          <ng-container *ngFor="let resource of resources">
            <app-coaching-log-resource-list-item
              [resourceListItem]="resource"
              [userDistrictId]="userDistrictId"
              [isLoading]="loading"
              (removeResourceEvent)="removeResource($event)" />
          </ng-container>
        </ng-container>
        <ng-template #noResources>
          <div class="text-center p-5 w-100">
            Use the search tool above to add resources to this session.
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100" style="background-color: #fff; height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
